import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getStats } from '../actions/drsearch';
// import { data } from 'jquery';

const MainAboutUs = (props) => {
    const [stats, getStat] = useState('');


    return (

        <div>
            <section id="about" class="about">
                <div class="container">
                    <div class="section-title"><h2>About Us</h2></div>
                    <div class="row">
                        <h5>GetMeHeal is the pioneer in providing technological solutions in Medical field. We have several years of experience of working with medical specialists to provide technological solutions. We have been working on solutions, which have potential to revolutionize the lives of both doctors and patients.</h5>
                        <h5>Our goal is to digitize patient and doctor's need using latest technology. Covid has worked as a catalyst in the adoption of virtual treatment and people have understood the power of "instant and 24x7 consultation". We are working on a mission to elevate patient-doctor's experience to next level by solving the fundamental problems of physical consultation.</h5>
                        <h5>Early detection of disease, is the best treatment and is the only way to improve life expectancy. Balanced diet, workouts and work-life-balance are the fundamentals of healthy life but regular checkups, consultations and early detection of diseases makes sure that we can overcome the unforeseen medical issues.</h5>
                        <h5>People in high life expectancy societies like Korea, US and Japan consults with specialists very frequently (as high as 16 consultations per year). There is direct relation in healthy life, life expectancy and consultation with specialist doctors. in India, on an average, people consults with specialists only two times a year. When they find a critical disease, its too late to do treatment and hence life expectancy keeps low. </h5>
                        <h5>We have taken this on a mission level to help both doctors and patients by bringing them close to each other. People can have frequent access to specialists doctors by utilizing "instant and 24x7 consultation" and reducing overall OPD time of specialists doctors.</h5>
                    </div>
                    <div class="section-title mt-4"><h2>What do we do</h2></div>
                    <div class="row">
                        <h5>We offer a wide range of services for both doctors and patients. We are on a mission to revolutionize medical treatment industry to make it available for each individuals.</h5>
                        <h5>We want to make sure that patients and doctors can feel same level of comfort in virtual consultation as they do in face to face consultation.</h5>
                    </div>
                    <div class="section-title mt-4"><h2>What makes us different</h2></div>
                    <div class="row">
                        <h5>In addition to basic services such as online appointment booking, online consultation and digital prescription, we have several differentiation.</h5>
                        <h5>At first, we are trying to solve the problem of finding a suitable doctor for you by providing a database of doctors, clinics and hospitals at PAN India level. Patients can search a doctor as per their requirement and can help others by adding a doctor, patient or hospital's information in our database. </h5>
                        <h5>We are also providing a single platform for patients to check the consolidated ratings of the doctors given by patients on multiple platforms. You do not need to browse multiple platforms to check the credibility and ratings of the doctors.</h5>
                        <h5>We are working on many such differentiation which will make your life convenient.</h5>
                    </div>
                    <div class="section-title mt-4"><h2> Best use cases of online consultation</h2></div>
                    <div class="row"><h5>Online consultation has become better than face to face consultation due to easy of appointment booking, zero waiting time at clinics and getting rid of hassle of travel. Spend couple of minutes to book an appointment and 5-15 min for consultation, anytime, anywhere. No need to worry about your office commitments, meetings and kid's schools etc.</h5>
                        <h5>If you are worried that your doctor did not pay enough attention to your past treatment records, diagnoses reports, due to crowded OPD and limited time. Stop worrying and try online consultation, where you can share your past records, before having virtual consultation. Get satisfied by having a follow-up appointment without any additional cost within 3 days.</h5>
                        <h5>If you have complex disease and want to have second consultation to reach to the conclusion before taking big decisions, such as surgery, hospitalization etc., Online, anywhere, anytime consultation is best option to get your doubts clarified.</h5>
                        <h5>Do not wait for next visit to your gyna for regular check-up during pregnancy or for next morning. You can now avail consultation anytime, anywhere on your finger tips by making online appointment and consultation.</h5>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default MainAboutUs
