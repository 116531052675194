import React, { useEffect, useState } from 'react';
import { getdrdetails, getdr } from '../../actions/drsearch';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment'
import { savePrescription, getPatientforDoctor, getPrescription } from '../../actions/user';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const PrescriptionBuild = (props) => {
    var { uname } = useParams();
    var prescription_id = "";
    var appointment_id = "";
    appointment_id = uname.split('-')[1];
    if (uname.length > 2) {
        prescription_id = uname.split('-')[2];
    }
    uname = uname.split('-')[0];
    const [prescription, setPrescription] = useState([]);
    const dispatch = useDispatch();
    const { drlist: drsearchLs } = useSelector(state => state.drsearch);
    const [data, setData] = useState({
        "notes": "",
        "diagnose": "",
        "tests": "",
        "others": "",
        "medicines": []
    });
    const [otherData, setotherData] = useState({
        "weight": "",
        "height": "",
        "bp": "",
        "pulse": "",
        "extra": "",
        "next_appointment": ""
    });
    const [drdetails, setContent] = useState('');
    const [patientdetails, setpatientdetails] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const MySwal = withReactContent(Swal)
    var userprofile = JSON.parse(localStorage.getItem("profile"));

    useEffect(() => {
        if (!!userprofile) {
            console.log("prescription_id", prescription_id)
            if (!!prescription_id > 0) {
                getPrescription(userprofile.username, appointment_id, prescription_id).then((resP) => {
                    setData(resP.data);
                    setPrescription(resP.data.medicines);
                    setotherData(resP.data.others)
                    console.log(resP.data);
                })
            }
            var drSearch = {
                "filter": { "uniquename": userprofile.username },
                "containingText": { "doctor_name": "" },
                "sort": {
                    "fee": "1"
                }
            }
            dispatch(getdr(drSearch))
                .then(() => {
                })
                .catch((ex) => {
                    console.log(ex)
                });
            getdrdetails(userprofile.username)//"dr-kirin-varghese-cardiologist")//
                .then((res) => {
                    console.log("getdrdetails", drsearchLs)
                    setContent(res.data);
                });
            getPatientforDoctor(appointment_id, uname)//"dr-kirin-varghese-cardiologist")//
                .then((res) => {
                    console.log("getPatientforDoctor", res)
                    setpatientdetails(res.data);
                });
        }
    }, []);
    //setData(othersInput)
    const addTableRows = () => {

        const rowsInput = {
            medicine: '',
            frequency: '',
            dose: '',
            days: '',
            notes: ''
        }
        setPrescription([...prescription, rowsInput])
    }
    const deleteTableRows = (index) => {

        const rows = [...prescription];
        rows.splice(index, 1);
        setPrescription(rows);
    }
    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const rowsInput = [...prescription];
        rowsInput[index][name] = value;
        setPrescription(rowsInput);

    }
    const dataHandleChange = (evnt) => {
        const { className, textContent } = evnt.target;
        const dataInput = data
        dataInput[className] = textContent;
        setData(dataInput);
    }
    const otherHandleChange = (evnt) => {
        console.log(evnt);
        const { id, value } = evnt.target;
        const othersInput = otherData
        othersInput[id] = value;
        setotherData(othersInput);
    }
    const drEduAr = [];
    const drEdu = drdetails && drdetails[0]["educations"] && drdetails[0]["educations"].map(item => {
        drEduAr.push(item.split('-')[0])
    })
    const savePrescriptions = (drUName) => {
        const ot = otherData;
        ot['next_appointment'] = startDate;
        setotherData(ot);
        data.others = otherData;
        data.medicines = prescription;
        savePrescription(drUName, appointment_id, data)
            .then((res) => {
                console.log("data", res);
                MySwal.fire({
                    title: <strong>Prescription Save!</strong>,
                    icon: 'success'
                })
            });
    }
    const doctorsList = prescription.map((item, index) => (
        <div class="med card px-2 my-2 py-2">
            <div className="row my-2">
                <div className="col-md-6">
                    <input class="med_name form-control" data-toggle="tooltip"
                        title="Click to edit..." placeholder="Enter medicine name" defaultValue={item.medicine}
                        onChange={(evnt) => (handleChange(index, evnt))} name="medicine"
                    />
                </div>
                <div className="col-md-3">
                    <div class="sc_time folded">
                        <select class="sc form-control" defaultValue={item.frequency}
                            onChange={(evnt) => (handleChange(index, evnt))} name="frequency">
                            <option value="" defaultValue disabled>Dosages</option>
                            <option value="1+1+1">1+1+1</option>
                            <option value="1+0+1">1+0+1</option>
                            <option value="0+1+1">1+1+1</option>
                            <option value="1+0+0">1+1+1</option>
                            <option value="0+0+1">1+1+1</option>
                            <option value="1+1+1+1">1+1+1+1</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="taking_time select folded">
                        <select class="meal form-control" defaultValue={item.dose}
                            onChange={(evnt) => (handleChange(index, evnt))} name="dose">
                            <option value="" defaultValue disabled>Condition</option>
                            <option value="After Meal" >After Meal</option>
                            <option value="Before Meal">Before Meal</option>
                            <option value="Take any time">Take any time</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row my-2">
                <div class="period folded col-md-4">
                    <select class="days form-control" defaultValue={item.days}
                        onChange={(evnt) => (handleChange(index, evnt))} name="days">
                        <option value="" defaultValue disabled>How Many Days</option>
                        <option value="1 Day" >1 Day</option>
                        <option value="3 Days">3 Days</option>
                        <option value="5 Days">5 Days</option>
                        <option value="1 Week">1 Week</option>
                        <option value="2 Weeks">2 Weeks</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div class="del_action col-md-6">
                    <input class="med_name form-control" data-med_id="{{med_id}}" data-toggle="tooltip"
                        title="Click to edit..." placeholder="Comment" defaultValue={item.notes}
                        onChange={(evnt) => (handleChange(index, evnt))} name="notes" />
                </div>
                <div class="del_action col-md-2">
                    <button data-med_id="{{med_id}}" class="btn btn-sm btn-danger delete" onClick={() => (deleteTableRows(index))}><i class="fa fa-trash"
                        aria-hidden="true" ></i></button>
                </div>
            </div>
        </div>
    ))
    return (
        <div>
            {drdetails ?
                <div className='row mt-5 mr-1' style={{ 'marginRight': 'unset' }}>
                    {/* <div className='col-md-3'></div> */}
                    <div className='col-md-12' style={{ 'padding': '0 15%' }}>
                        <div class="card shadow-lg">
                            <div class="card-body">
                                <div class="prescription_form border">
                                    <div className='row px-3'>
                                        <div class="col-md-6">
                                            <small><b>For Appointment : </b>{drsearchLs && drsearchLs[0].googleInfo ? drsearchLs[0].googleInfo.ph : ""}</small>
                                            <br />
                                            <small><b>Email : </b>{drdetails ? drdetails[0].email : ""}</small>
                                            <br />
                                            {drdetails && drdetails[0]["clinics"] && drdetails[0]["clinics"].map(cl => (
                                                `${cl.name}`
                                            )).join()}
                                        </div>
                                        <div class="col-md-6 text-end">
                                            <h4>{drsearchLs && drsearchLs[0].doctor_name ? drsearchLs[0].doctor_name.toLocaleUpperCase().replaceAll('-', ' ') : drdetails[0].uniquename.toLocaleUpperCase().replaceAll('-', ' ')}</h4>
                                            {/* <h4>{drdetails[0].uniquename.toLocaleUpperCase().replaceAll('-', ' ')}</h4> */}
                                            <p className='mb-1'>{drEduAr.join()}</p>
                                            <small><b>{drdetails && drdetails[0]["registrations"] ? "Registration No:" + drdetails[0]["registrations"][0] : ""} </b></small>
                                        </div>

                                    </div>
                                    <div class="row ps-4">
                                        <div class="col-md-12">

                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div class="row ps-3">
                                        <div class="col-md-6">
                                            <b>Name:</b><label style={{ textTransform: 'capitalize' }}>{patientdetails && patientdetails.name ? patientdetails.name : uname}</label>
                                            {patientdetails && patientdetails.age ? <div><b>Age/Sex:</b>{patientdetails.age}</div> : ""}
                                            {/* <b>ID:</b>GE001<br /> */}
                                        </div>
                                        <div class="col-md-6 pe-4" style={{ 'text-align': 'right' }}>
                                            <b>Genrated Date:</b>{moment(new Date()).format('DD-MM-YYYY, h:mm a')}
                                            {patientdetails && patientdetails.email ? <div><b>Email:</b>{patientdetails.email}</div> : ""}
                                            {patientdetails && patientdetails.mobile ? <div><b>Mobile:</b>{patientdetails.mobile}</div> : ""}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="desease_details">
                                                <div class="symptoms">
                                                    <h4 class="d-header">Symptoms</h4>
                                                    <ul class="tests" data-toggle="tooltip" data-placement="bottom" title="Click to edit."
                                                        contenteditable="true" defaultValue={data.tests} onInput={dataHandleChange} name="tests">
                                                    </ul>
                                                </div>
                                                <div class="tests">
                                                    <h4 class="d-header">Lab Test / X-Ray</h4>
                                                    <ul class="diagnose" data-toggle="tooltip" data-placement="bottom" title="Click to edit."
                                                        contenteditable="true" defaultValue={data.comment} onInput={dataHandleChange} name="diagnose">
                                                    </ul>
                                                </div>
                                                <div class="advice">
                                                    <h4 class="d-header">Comment</h4>
                                                    <p class="notes" data-toggle="tooltip"
                                                        data-placement="bottom" title="Click to edit." contenteditable="true" defaultValue={data.notes} onInput={dataHandleChange} name="notes">
                                                    </p>
                                                    {/* <div class="adv_action">
                                    <button id="adv_save" data-prescription_id="<?php echo $presc->prescription_id; ?>" class="btn btn-sm btn-success save">Save</button>
                                    <button class="btn btn-sm btn-danger cancel-btn">Cancel</button>
                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <span class="display-6">R<sub>x</sub></span>
                                                </div>
                                                <div className='col-md-3'>
                                                    <input type="text" class="form-control weight" placeholder="Weight" id="weight"
                                                        defaultValue={otherData.weight}
                                                        onInput={otherHandleChange} aria-label="Weight" />
                                                </div>
                                                <div className='col-md-3'>
                                                    <input type="text" class="form-control height" placeholder="Height" id="height"
                                                        defaultValue={otherData.height}
                                                        onInput={otherHandleChange} aria-label="Height" />
                                                </div>
                                                <div className='col-md-3'>
                                                    <input type="text" class="form-control bp" placeholder="BP" id="bp"
                                                        defaultValue={otherData.bp}
                                                        onInput={otherHandleChange} aria-label="BP" />
                                                </div>
                                                <div className='col-md-1'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-3'>
                                                    <input type="text" class="form-control pulse" placeholder="Pulse" id="pulse"
                                                        defaultValue={otherData.pulse}
                                                        onInput={otherHandleChange} aria-label="Pulse" />
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="medicine">
                                                <div class="med_list my-3">
                                                    {doctorsList}
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <button id="add_med" data-toggle="tooltip" data-placement="right"
                                                            title="A" onClick={addTableRows} className="btn btn-primary  btn-sm ms-3 rounded-pill d-flex">Add Medicine</button>
                                                    </div>
                                                    <div className='col-md-3 pt-1'>Next Appointment:</div>
                                                    <div className='col-md-5 pe-4'>
                                                        <DatePicker onChange={(date) => setStartDate(date)}
                                                            minDate={new Date()} placeholderText="Next Appointment" isClearable={true} defaultValue={otherData.next_appointment} />
                                                    </div>
                                                </div>

                                            </div>

                                            <hr />
                                        </div>
                                    </div>
                                    <div class="button_group">
                                        <button class="issue_prescription btn btn-success" onClick={() => savePrescriptions(drdetails[0].uniquename)}>Issue</button>
                                        <button class="pdf_prescription btn btn-danger">PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
        </div>
    )
}
export default PrescriptionBuild