export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const DOCTORS_SUCCESS = 'DOCTORS_SUCCESS';
export const DOCTORS_FAIL = 'DOCTORS_FAIL';
export const PROFILE = 'PROFILE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const  DRLIST_SUCCESS='DRLIST_SUCCESS';
export const  DRLIST_FAIL='DRLIST_FAIL';
export const  DRDETAILS_SUCCESS='DRDETAILS_SUCCESS';
export const  DRDETAILS_FAIL='DRDETAILS_FAIL';
