import axios from 'axios';
import authHeader from './auth-header';
const API_URL = 'https://easycure.onrender.com/';

const register = (data) => axios.post(`${API_URL}auth/registerUser`, data)
  .then(response => {
    if (response.data.jwt) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response;
  });

const login = (email, password, role) => axios
  .post(`${API_URL}auth/login`, {
    "username": email,
    "password": password,
    "role": role,
  })
  .then(response => {
    debugger;
    if (response.data.access_token) {
      response.data.isdoctor = role
      localStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });

const logout = (user) =>
  axios.get(`${API_URL}auth/logout?token=${user.access_token}`)
    .then(res => {
      console.log(res);
      localStorage.clear();
      window.location = "/";
      return res;
    });
const verifypayment = (id) =>
  axios.get(`${API_URL}restrictedArea/paymentStatus/${id}`,{ headers: authHeader() })
    .then(res => {
      return res;
    });

export default {
  register,
  login,
  logout,
  verifypayment,
};
