import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getStats } from '../actions/drsearch';
// import { data } from 'jquery';

const Aboutus = (props) => {
  const [stats, getStat] = useState({});

  // constructor(props) {

  //   super(props)
  //   this.state = {
  //     "username": "user8",
  //     "password": "user8",
  //     "email": "lovinpcs@gmail.com",
  //     "mobile": "+918377003206"
  //   }
  // }
  // componentDidMount() {

  //   // this.loginPatient(this);
  //   //this.logoutPatient(this, "0e1b04a87367199c593f7a59a3321f51b3bd9083");
  //   // this.registerPatient(this);
  //   //this.drDetails(this,"dr-kirin-varghese-cardiologist");
  //   //this.addDr(this, {"doctor_name":"testdr0a"});
  //   //this.addHospital(this, {"name":"Test Hospital11","practice_type":"clinic"});
  //   // this.getOtp(this, "user8","email","lovinpcs@gmail.com");
  //   // this.otpLogin(this, {"username":"user8","mode":"email","otp":"1057"});
  //   // this.bookAppointment(this, "testdr", "Bearer dd532d5eea2b3a579511480d073a23c100701725");
  //   //conferenceCall api first parameter is call id received after booking and second is access_token
  //   var callUrl = this.conferenceCall(this, "testdr68a92865285ce", "0d777ea4b1b80ffc60bd4956a169de7addf9c0d0");
  //   console.log(callUrl);
  //   getStats()
  //     .then((res) => {
  //       this.getStat(res.data.results);

  //     });
  // }
  useEffect(() => {
    getStats()
      .then((res) => {
        debugger;
        if(!!res.data){
          getStat(res.data);
        }
        console.log(res);

      });
  },[])

  // handlerChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value })
  // }
  // registerPatient = (e) => {
  //   e.preventDefault()
  //   axios.post("https://easycure.herokuapp.com/auth/registerUser", this.state)
  //     .then(res => {
  //       console.log(res);
  //       alert(res.data.message);
  //     })
  // }
  // loginPatient = (e) => {
  //   e.preventDefault()
  //   console.log(this.state)
  //   axios.post("https://easycure.herokuapp.com/auth/login", this.state)
  //     .then(res => {
  //       console.log(res);
  //       this.access_token = res.data.access_token;
  //       localStorage.access_token = this.access_token;
  //       alert(this.access_token);
  //     })
  // }
  // conferenceCall = (e, callId, token) => {
  //   return "https://easycure.herokuapp.com/call?id=" + callId + "&token=" + token;
  // }


  return (

    <div>
      <section id="about" class="about">
        <div class="container">

          <div class="row">
            <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
              {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox play-btn mb-4"></a> */}
            </div>

            <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5" style={{ 'padding-left': '5rem', 'padding-right': '5rem' }}>
              <h2 className='text-Colored'>This portal is incomplete without your contribution. If you are aware of a doctor, clinic or hospital which is not available on this portal, please register it now. </h2>


              {/* <div class="icon-box">
                  <div class="icon"><i class="bx bx-fingerprint"></i></div>
                  <p class="description">This portal is incomplete without your contribution. If you are aware of a doctor, clinic or hospital which is not available on this portal, please register it now. </p>
                </div>

                <div class="icon-box">
                  <div class="icon"><i class="bx bx-gift"></i></div>
                  <p class="description">Also give us your valuable feedback, if you find any inconsistency in existing information, we are continuously working to make the whole data accurate and up to dat with your help.</p>
                </div> */}
              <div class="d-grid gap-2 col-6 mx-auto mt-3">
                <button class="btn btn-primary" type="button">Add a Doctor/Clinic/Hospital</button>
              </div>
              {/*   
              <div class="icon-box">
                <div class="icon"><i class="bx bx-atom"></i></div>
                <h4 class="title"><a href="">Dine Pad</a></h4>
                <p class="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p>
              </div> */}

            </div>
          </div>

        </div>
      </section>
      <section id="counts" class="counts">
        <div class="container">

          <div class="row">

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fas fa-user-md"></i>
                <span data-purecounter-start="0" data-purecounter-end={!!stats.dr?stats.dr:0} data-purecounter-duration="1" class="purecounter"></span>
                <p>Doctors</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div class="count-box">
                <i class="fas fa-hospital-symbol"></i>
                <span data-purecounter-start="0" data-purecounter-end={!!stats.clinic?stats.clinic:0}  data-purecounter-duration="1" class="purecounter"></span>
                <p>Clinics</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div class="count-box">
                <i class="fas fa-hospital"></i>
                <span data-purecounter-start="0" data-purecounter-end={!!stats.hospital?stats.hospital:0} data-purecounter-duration="1" class="purecounter"></span>
                <p>Hospitals</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div class="count-box">
                <i class="fas fa-procedures"></i>
                <span data-purecounter-start="0" data-purecounter-end={!!stats.users?stats.users:0} data-purecounter-duration="1" class="purecounter"></span>
                <p>Patients</p>
              </div>
            </div>

          </div>

        </div>
      </section>
      {/* <div class="modal fade" id="modalLRForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content login">
              <div class="modal-header">
                <h3 class="modal-title w-100 dark-grey-text font-weight-bold" id="myModalLabel"><strong>Sign in</strong></h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="d-flex flex-column text-center">
                  <form onSubmit={this.loginPatient}>
                    <div class="form-group mb-3">
                      <input type="text" class="form-control" id="email1" placeholder="Mobile Number / Email ID" value={username} onChange={this.handlerChange} name="username" />
                    </div>
                    <div class="form-group mb-3">
                      <input type="password" class="form-control" id="password1" placeholder="Password" value={password} onChange={this.handlerChange} name="password" />
                    </div>
                    <button type="submit" class="btn btn-info btn-block btn-round appointment-btn" style={{ width: '100%', 'margin-left': 'unset' }}>Login</button>
                  </form>

                  <div class="text-center text-muted delimiter">or use a social network</div>
                  <div class="d-flex justify-content-center social-buttons">
                    <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Twitter">
                      <i class="fab fa-twitter"></i>
                    </button>
                    <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Facebook">
                      <i class="fab fa-facebook"></i>
                    </button>
                    <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Linkedin">
                      <i class="fab fa-linkedin"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <div class="signup-section">Not a member yet? <a href="#" class="text-info" id="signup" type="button"> Sign Up</a>.</div>
              </div>
            </div>
            <div class="modal-content d-none register">
              <div class="modal-header">
                <h3 class="modal-title w-100 dark-grey-text font-weight-bold" id="myModalLabel"><strong>Join XYZ</strong></h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="d-flex flex-column text-center">
                  <form>
                    <div class="form-group mb-3">
                      <input type="email" class="form-control" id="name" placeholder="Full Name" />
                    </div>
                    <div class="form-group mb-3">
                      <input type="number" class="form-control" id="mobile" placeholder="Mobile Number" />
                    </div>
                    <div class="form-group mb-3">
                      <input type="password" class="form-control" id="cpassword1" placeholder="Password" />
                    </div>
                    <button type="button" class="btn btn-info btn-block btn-round appointment-btn" style={{ width: '100%', 'margin-left': 'unset' }}>Send Otp</button>
                  </form>

                  <div class="text-center text-muted delimiter">or use a social network</div>
                  <div class="d-flex justify-content-center social-buttons">
                    <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Twitter">
                      <i class="fab fa-twitter"></i>
                    </button>
                    <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Facebook">
                      <i class="fab fa-facebook"></i>
                    </button>
                    <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Linkedin">
                      <i class="fab fa-linkedin"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <div class="signup-section">Already member? <a href="#" class="text-info" id="signin"> Sign In</a>.</div>
              </div>
            </div>
          </div>
        </div> */}

    </div>

  )
}

export default Aboutus
