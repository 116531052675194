import React from 'react';
export default (params) => {
  return (
    <section id="appointment" class="appointment">
      <div class="container">

        <div class="section-title">
          <h2>Make an Appointment</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
        <div class="row">
          <div class="col-lg-6 details">
            <h4 className="mb-4">GetMeHeal provides online consultation with Experienced and renowned doctors throughout India.</h4>
            <h4 className="mb-4">Save your time and money, if you are not sure about your disease and specialist, you need to consult with. We have online consultation with a panel of experts to guide you in deciding your next steps.</h4>
            <h4 className="mb-4">Book your appointment in advance, after completing simple registration.</h4>
            <div class="text-center mb-4"><a href='/dr' className="btn btn-primary">Book an online consultation appointment</a></div>
          </div>
          <div class="col-lg-6">
            <img src="assets/img/dr1.png" style={{ 'maxWidth': '70%', "float": "right" }} alt="" class="img-fluid" />
          </div>
        </div>
        {/* <form action="forms/appointment.php" method="post" role="form" class="php-email-form">
            <div class="row">
              <div class="col-md-4 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"/>
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email"/>
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3 mt-md-0">
                <input type="tel" class="form-control" name="phone" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars"/>
                <div class="validate"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 form-group mt-3">
                <input type="datetime" name="date" class="form-control datepicker" id="date" placeholder="Appointment Date" data-rule="minlen:4" data-msg="Please enter at least 4 chars"/>
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3">
                <select name="department" id="department" class="form-select">
                  <option value="">Select Department</option>
                  <option value="Department 1">Department 1</option>
                  <option value="Department 2">Department 2</option>
                  <option value="Department 3">Department 3</option>
                </select>
                <div class="validate"></div>
              </div>
              <div class="col-md-4 form-group mt-3">
                <select name="doctor" id="doctor" class="form-select">
                  <option value="">Select Doctor</option>
                  <option value="Doctor 1">Doctor 1</option>
                  <option value="Doctor 2">Doctor 2</option>
                  <option value="Doctor 3">Doctor 3</option>
                </select>
                <div class="validate"></div>
              </div>
            </div>
  
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" rows="5" placeholder="Message (Optional)"></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your appointment request has been sent successfully. Thank you!</div>
            </div>
           
          </form> */}

      </div>
    </section>

  )
}