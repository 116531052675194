import React, { useEffect, useState } from 'react';
import { getdrdetails, getdr } from '../../actions/drsearch';
import { useParams } from 'react-router-dom';
import * as moment from 'moment'
import { savePrescription, getPrescription } from '../../actions/user';
import { useSelector, useDispatch } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const ViewPrescription = (props) => {
    const [loading, setLoading] = useState(false);
    var { uname } = useParams();
    var prescription_id = "";
    var appointment_id = "";
    prescription_id = uname.split('--')[2];
    appointment_id = uname.split('--')[1];
    uname = uname.split('--')[0];
    const [prescription, setPrescription] = useState([]);
    const [data, setData] = useState({});
    const [drdetails, setContent] = useState('');
    const dispatch = useDispatch();
    const { drlist: drsearchLs } = useSelector(state => state.drsearch);
    var userprofile = JSON.parse(localStorage.getItem("profile"));


    useEffect(() => {
        if (!!userprofile) {
            setLoading(true);
            var drSearch = {
                "filter": { "uniquename": uname },
                "containingText": { "doctor_name": "" },
                "sort": {
                    "fee": "1"
                }
            }
            dispatch(getdr(drSearch))
                .then(() => {
                })
                .catch((ex) => {
                    console.log(ex)
                    setLoading(false);
                });

            getdrdetails(uname)//"dr-kirin-varghese-cardiologist")//
                .then((res) => {
                    setContent(res.data);
                });
            getPrescription(uname, appointment_id, prescription_id).then((resP) => {
                setData(resP.data);
                setPrescription(resP.data.medicines);
                console.log(resP.data);
                setLoading(false);
            }).catch((ex) => {
                console.log(ex)
                setLoading(false);
            });

        }
    }, []);
    //setData(othersInput)


    const drEduAr = [];
    const drEdu = drdetails && drdetails[0]["educations"] && drdetails[0]["educations"].map(item => {
        drEduAr.push(item.split('-')[0])
    })
    const doctorsList = prescription.map((item, index) => (

        <tr>
            <td>{item.medicine}</td>
            <td>{item.frequency}</td>
            <td>{item.dose}</td>
            <td>{item.days}</td>
            <td>{item.notes}</td>
        </tr>
    ))
    const downloadPdf = () => {
        var printContents = document.getElementById('pdfPres').innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    }
    return (
        <div>
            {drdetails ?
                <div className='row mt-5 mr-1' style={{ 'marginRight': 'unset' }}>
                    {/* <div className='col-md-3'></div> */}
                    <div className='col-md-12' style={{ 'padding': '0 15%' }}>
                        <div class="card shadow-lg" id='pdfPres'>
                            <div class="card-body">
                                <div class="prescription_form border">
                                    <div className='row px-3'>
                                        <div class="col-md-6">
                                            <small><b>For Appointment : </b>{drsearchLs && drsearchLs[0] ? drsearchLs[0].googleInfo.ph : ""}</small>

                                            <br />
                                            <small><b>Email : </b>{drdetails ? drdetails[0].email : ""}</small>
                                            <br />
                                            {
                                                drdetails && drdetails[0]["clinics"] && drdetails[0]["clinics"].map(cl => (
                                                    `${cl.name}`
                                                )).join()
                                            }
                                        </div >
                                        <div class="col-md-6 text-end">
                                            <h4>{drsearchLs && drsearchLs[0].doctor_name ? drsearchLs[0].doctor_name.toLocaleUpperCase().replaceAll('-', ' ') : drdetails[0].uniquename.toLocaleUpperCase().replaceAll('-', ' ')}</h4>
                                            {/* <h4>{drdetails[0].uniquename.toLocaleUpperCase().replaceAll('-', ' ')}</h4> */}
                                            <p className='mb-1'>{drEduAr.join()}</p>
                                            <small><b>{drdetails && drdetails[0]["registrations"] ? "Registration No:" + drdetails[0]["registrations"][0] : ""} </b></small>
                                        </div>

                                    </div >
                                    <div class="row ps-4">
                                        <div class="col-md-12">

                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div class="row ps-3">
                                        <div class="col-md-6">
                                            <b>Name:</b><label style={{ textTransform: 'capitalize' }}>{userprofile && userprofile.name ? userprofile.name : uname}</label><br />

                                            <b>Age/Sex:</b>23 / M < br />
                                            {/* <b>ID:</b>{prescription_id}<br /> */}
                                        </div >
                                        <div class="col-md-6 pe-4" style={{ 'text-align': 'right' }}>
                                            <b>Genrated Date:</b>{moment(new Date()).format('DD-MM-YYYY, h:mm a')}<br />
                                            {userprofile && userprofile.mobile ? <div><b>Mobile:</b>{userprofile.mobile}</div> : ""}
                                            {data && data.others ? <div><b>Next Appointment: </b>{moment(data.others.next_appointment).format('DD-MM-YYYY')}</div> : ""}
                                        </div>
                                    </div >
                                    <hr></hr>
                                    <div class="row ps-3">
                                        <div class="col-md-12">
                                            <div class="desease_details">
                                                <div class="symptoms">
                                                    <span><b>Symptoms : </b>{data.tests}</span>
                                                </div>
                                                <div class="tests">
                                                    <span><b>Lab Test / X-Ray : </b>{data.diagnose}</span>
                                                </div>
                                                <div class="advice">
                                                    <span><b>Comment : </b>{data.notes}</span>
                                                </div>
                                                <div class="Vitals">
                                                    <span><b>Vitals : </b>
                                                        {data && data.others && data.others.weight ? "Weight: " + data.others.weight + "," : ""}
                                                        {data && data.others && data.others.height ? "Height: " + data.others.height + "," : ""}
                                                        {data && data.others && data.others.bp ? "BP: " + data.others.bp + "," : ""}
                                                        {data && data.others && data.others.pulse ? "Pulse: " + data.others.pulse + "," : ""}
                                                        {data && data.others && data.others.extra ? "Extra: " + data.others.extra : ""}
                                                        {/* {data.others.next_appointment ? "Next Appointment: "+data.others.next_appointment:""} */}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Medicine Name</th>
                                                        <th scope="col">Frequency</th>
                                                        <th scope="col">Condition</th>
                                                        <th scope="col">Duration</th>
                                                        <th>notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {doctorsList}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="button_group text-center pt-2">
                            {/* <button class="issue_prescription btn btn-success">Issue</button> */}
                            <button class="pdf_prescription btn btn-primary" onClick={downloadPdf}>Print</button>
                        </div>
                    </div>
                </div >
                : ""}
    <div className={"overlay " + (loading ? '' : 'd-none')}>
        <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
    </div>
        </div >
    )
}
export default ViewPrescription