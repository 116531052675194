import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { data } from 'jquery';
import Patient from '../../services/patient.service';
import DataTable from "react-data-table-component";
import * as moment from 'moment';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { logout } from '../../actions/auth';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const MyAppointment = (props) => {
    const dispatch = useDispatch();
    const [myAppList, setContent] = useState('');
    const [url, setUrl] = useState('');
    const { user: currentUser } = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal);
    const joincall = (callId, drname) => {
        window.$('#exampleModalLabel')[0].innerText = 'Video Confrence With ' + drname.split('-').join(' ');
        const user = JSON.parse(localStorage.getItem('user'));
        console.log("https://easycure.onrender.com/call?id=" + callId + "&token=" + user.access_token)
        setUrl("https://easycure.onrender.com/call?id=" + callId + "&token=" + user.access_token)
        window.$('#vdocall').modal('show');

        setTimeout(function () {
            console.log("dsdghsgdhsgdh");
            window.$('#connect-button').click();
        }, 2000);

    }

    const columns = [
        {
            id: 1,
            name: "Doctor Name",
            selector: (row) => row.uniquename,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Date & Time",
            selector: (row) => moment(row.slotStartsAt).format('DD-MM-YYYY, h:mm a'),
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Status",
            selector: (row) => (<div>{row.status == "BOOKED" ? <button type="button" class="btn btn-success btn-sm">{row.status}</button> : <button type="button" class="btn btn-danger btn-sm">PAYMENT DUE</button>}</div>),
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Update",
            cell: (row) => (<div className="d-inline-flex">
                {row.status == "BOOKED" ? <button class="btn btn-sm btn-outline-primary w-100 me-2" onClick={() => joincall(row.callId, row.uniquename)}><i class="bi bi-camera-video-fill  me-2"></i></button> : ""}
                {row.prescription_id ? <a class="btn btn-sm btn-outline-primary w-100" href={"/viewprescription/" + row.uniquename + "--" + row._id + "--" + row.prescription_id}><i class="bi bi-download  me-2" title="Prescription"></i></a> : ""}
            </div>),
            sortable: true,
            center: true,
            reorder: true
        }
    ];
    useEffect(() => {
        setLoading(true);
        Patient.getPatientAppointments()
            .then((res) => {
                console.log(res.data.data);
                setContent(res.data.data);
                //setSuccessful(true);
                setLoading(false);
            }).catch((ex) => {
                if (window.$(ex)[0].response.status === 440) {
                    setLoading(false);
                    MySwal.fire({
                        title: <strong>Session was expired please relogin</strong>,
                        icon: 'error'
                    }).then((result) => {
                        const user = JSON.parse(localStorage.getItem('user'))
                        setLoading(true);
                        dispatch(logout(user))
                            .then((data) => {
                                setLoading(false);
                                // console.log("Profile", data);
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                        return <Navigate to="/" />;
                    })
                }
            });
    }, []);

    if (!currentUser) {
        return <Navigate to="/" />;
    }
    return (
        <div>
            <div className='row mt-5 mr-1' style={{ 'marginRight': 'unset' }}>
                <div className='col-md-12' style={{ 'padding': '0 15%' }}>
                    <div class="card shadow-lg">
                        <div class="card-body"> <DataTable
                            columns={columns}
                            data={myAppList}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                        />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade vCall" id="vdocall" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel" style={{ 'text-transform': 'capitalize' }}></h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => window.$('#vFrame').attr('src', window.$('#vFrame').attr('src'))}></button>
                        </div>
                        <div class="modal-body ratio ratio-16x9" >
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ "right": "0", "position": "absolute", "top": "-3px", "font-size": "small" }} onClick={() => window.$('#vFrame').attr('src', window.$('#vFrame').attr('src'))}></button> */}
                            <iframe src={url}
                                allow="camera; microphone" className='embed-responsive-item' id="vFrame" allowfullscreen>
                            </iframe>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={"overlay " + (loading ? '' : 'd-none')}>
                <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
            </div>
        </div >
    )
}
export default MyAppointment