import doctors from '../component/doctors';
import DrSearch from '../services/drsearch.service'
import { DRLIST_SUCCESS, DRLIST_FAIL, SET_MESSAGE, DRDETAILS_SUCCESS, DRDETAILS_FAIL } from './types';

export const getdr = (drsearch) => dispatch => DrSearch.postSearchDrs(drsearch).then(
    response => {
        //console.log("SrList", response);
        dispatch({
            type: DRLIST_SUCCESS,
            payload: { drlist: response.data },
        });

        dispatch({
            type: SET_MESSAGE,
            payload: "",
        });

        return response.data;
    })
export const getdrdetails = (drsearch) => DrSearch.drdetails(drsearch).then(
    response => {
        return response.data;
    })
export const autocomplete = (field, value) => DrSearch.autocomplete(field, value).then(
    response => {
        return response.data;
    })
export const saveRating = (dr, rating) => DrSearch.saveRating(dr, rating).then(
    response => {
        return response.data;
    })
export const getComments = (dr) => DrSearch.getComments(dr).then(
    response => {
        return response.data;
    })
export const saveComments = (dr, comment) => DrSearch.saveComments(dr, comment).then(
    response => {
        return response.data;
    })
export const getStats = (dr) => DrSearch.getStats().then(
    response => {
        return response.data;
    })
export const saveContact = (contactDt) => DrSearch.saveContact(contactDt).then(
    response => {
        return response.data;
    })
export const addDoctor = (doctor) => DrSearch.addDoctor(doctor).then(
    response => {
        return response.data;
    })
export const addHospital = (hospital) => DrSearch.addHospital(hospital).then(
    response => {
        return response.data;
    })
export const addIntimate = (id) => DrSearch.addIntimate(id).then(
    response => {
        return response.data;
    })
export const verificationData = (id) => DrSearch.verificationData(id).then(
    response => {
        return response.data;
    })
export const updateDrProfile= (id) => DrSearch.updateDrProfile(id).then(
    response => {
        return response.data;
    })