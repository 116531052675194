import React, { useEffect, useState } from 'react';
import { addDoctor, addHospital, verificationData } from '../../actions/drsearch';
// import { Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { logout } from '../../actions/auth';
export default (params) => {
    debugger;

    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        var elems = document.querySelector(".active");
        if (elems !== null) {
            elems.classList.remove("active");
        }
        window.$('#adddoctor').addClass('active');
        window.$("#adddocter").attr("href", "#");
    }, []);
    var drprofile = {
        "doctor_name": "",
        "practice": {
            "city": ""
        },
        "googleInfo": {
            "ph": "",
            "email": ""
        },
        "specialty_and_non_popular_services": [],
        "specializations": []
    }
    const [data, setData] = useState(drprofile);
    const handleChange = (evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = data;
        //console.log(rowsInput);
        var nmt = name.split('.')
        var ty = nmt[1];
        if (nmt.length = 2 && !!ty) {
            if (ty == 'array') {
                rowsInput[nmt[0]] = value.split(',');
            }
            else {
                rowsInput[nmt[0]][ty] = value;
            }
        }
        else {

            rowsInput[name] = value;
        }
        setData(rowsInput);
    }
    const addDoctors = (evnt) => {
        debugger;
        evnt.preventDefault()

        setLoading(true);
        if (evnt.target.elements.dr.checked) {
            addDoctor(data).then((es) => {
                setLoading(false);
                MySwal.fire({
                    title: <strong>Doctor Save!</strong>,
                    icon: 'success'
                })

            }).catch((ex) => {
                setLoading(false);
            });
        }
        if (evnt.target.elements.hosp.checked) {
            const rowsInput = data;
            data["name"] = data["doctor_name"];
            delete data["doctor_name"];
            addHospital(data).then((es) => {
                setLoading(false);
                MySwal.fire({
                    title: <strong>Hospital Save!</strong>,
                    icon: 'success'
                }).then((result) => {
                    window.location.reload()

                })
            }).catch((ex) => {
                setLoading(false);
            });
        }

    }
    return (
        <div style={{ 'background': 'linear-gradient(rgba(196, 102, 0, 0.6), rgba(155, 89, 182, 0.6))' }}>
            <section id="about" className="about">
                <div className="container" style={{ 'max-width': '450px' }}>
                    <div className="section-title"><h2>Add Doctor and Hospital</h2></div>
                    <form className="requires-validation" novalidate onSubmit={addDoctors} id="msform">
                        <ul id="progressbar">
                            <li className="active">Account Setup</li>
                            <li>Social Profiles</li>
                            <li>Verification Details</li>
                        </ul>
                        <fieldset>
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="doctor_name" placeholder="Enter Doctor / Hospital Name" required
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className='row mb-3'>
                                <div className="col-md-6">
                                    <input className="form-control" type="email" name="googleInfo.email" placeholder="E-mail Address"
                                        onChange={(evnt) => (handleChange(evnt))} />
                                </div>
                                <div className="col-md-6">
                                    <input className="form-control phonenumber" type="text" name="googleInfo.ph" placeholder="Phone / Mobile No"
                                        onChange={(evnt) => (handleChange(evnt))} />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="practice.city" placeholder="Enter City" required
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="specialty_and_non_popular_services.array" placeholder="Specialty With Commas" required
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className='row mb-3'>
                                <div className="col-md-6">
                                    <input className="form-control" type="text" name="experience_years" placeholder="Total Expiernce" required
                                        onChange={(evnt) => (handleChange(evnt))}
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                </div>
                                <div className="col-md-6">
                                    <input className="form-control" type="text" name="fee" placeholder="Fees" required
                                        onChange={(evnt) => (handleChange(evnt))}
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                </div>
                            </div>


                            <div className="col-md-12 mb-3">
                                {/* <label className="mb-3 mr-1" for="gender">Gender: </label> */}

                                <input type="radio" className="btn-check" name="drValue" id="dr" autocomplete="off" required />
                                <label className="btn btn-sm btn-outline-secondary me-2" for="dr">Doctor</label>

                                <input type="radio" className="btn-check" name="drValue" id="hosp" autocomplete="off" required />
                                <label className="btn btn-sm btn-outline-secondary" for="hosp">Hospital</label>

                            </div>
                            <div className='col-md-12 mt-3'>
                                <input type="button" name="next" className="next btn btn-primary" value="Next" />
                            </div>
                        </fieldset>
                        <fieldset>
                            {/* <h2 class="fs-title">Social Profiles</h2>
                            <h3 class="fs-subtitle">Your presence on the social network</h3> */}
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="specializations.array" placeholder="Specializations With Commas" required
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>

                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="awards.array" placeholder="Awards"
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="educations.array" placeholder="Educations"
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>

                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="memberships.array" placeholder="Memberships"
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="experience.array" placeholder="Expiernces"
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>

                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="registrations.array" placeholder="Registrations"
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type="text" name="training_certification.array" placeholder="Training Certification"
                                    onChange={(evnt) => (handleChange(evnt))} />
                            </div>
                            <div className='col-md-12 mb-3'>

                                <input type="button" name="previous" class="previous btn btn-primary me-4" value="Previous" />
                                <input type="button" name="next" class="next  btn btn-primary" value="Next" />

                            </div>
                        </fieldset>
                        <fieldset>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                                <label className="form-check-label">I confirm that all data are correct</label>
                                <div className="invalid-feedback">Please confirm that the entered data are all correct!</div>
                            </div>


                            <div className="form-button mt-3">
                                <input type="button" name="previous" class="previous btn btn-primary me-4" value="Previous" />
                                <button id="submit" type="submit" className="btn btn-primary">Register</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    )
}
