import { error } from 'jquery';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { verifypayment } from '../actions/auth';
const Callback = (props) => {
    var urlParams;
    const { id } = useParams();
    var count = 0;
    // var params = new URLSearchParams(url.search);
    // var status = params.get('status');
    const [status, setStatus] = useState();
    useEffect(() => {
        verifypayment(id)
            .then((res) => {
                console.log("verify", res)
                setStatus(res.data.status.resultCode)
                if(res.data.status.resultCode=="01" || res.data.status.resultCode=="02"||count==3){

                }
                else{
                    setTimeout( verifypayment(id),5000);
                }
            }, error => {
                console.log("verify", error)
            });

    }, []);
    return (<section className='text-center'>
        <div className="card callback">
            <div class="card-body">
                {status == "01" ?
                    <div>
                        <div className='circle'>
                            <i className="checkmark">✓</i>
                        </div>
                        <h1>Success</h1>
                    </div> : status == "02" ?
                        <div><div className='circle'>

                            <i className="checkmarkError">X</i>
                        </div>
                            <h1 className='failed'>Failed</h1></div> :
                        <div><div className='circle'>

                            <i className="checkmarkPending">!</i>
                        </div>
                            <h1 className='pending'>Processing</h1></div>}
                <span><b>Transaction Id</b>: {id}</span>
                <p>Please Check Your Appointment</p>
                <a class="btn btn-primary" type="button" href="/appointments">My Appointment</a>
            </div>
        </div></section >)
}


export default Callback