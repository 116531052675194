import {
    DRLIST_FAIL,
    DRLIST_SUCCESS,
  } from '../actions/types';
  
  const initialState = { drlist: [] };
  
  export default function drsearch(state = initialState, action) {
    const { type, payload } = action;
    // console.log("Payload",payload)
    switch (type) {
      case DRLIST_SUCCESS:
        return {
          ...state,
          drlist: payload.drlist,
        };
      case DRLIST_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
  