import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://easycure.onrender.com/';

const getDoctors = () => axios.get(`${API_URL}api/v1/doctors`, { headers: authHeader() });
const getDoctor = id => axios.get(`${API_URL}api/v1/doctors/${id}`, { headers: authHeader() });
const getPatientAppointments = () => axios.get(`${API_URL}restrictedArea/myAppointments`, { headers: authHeader() });
const getdrAppointment = (dr, status) => axios.get(`${API_URL}restrictedArea/${dr}/appointments/${status}`, { headers: authHeader() });
const bookAppointment = (doctorId) => axios.get(`${API_URL}restrictedArea/bookAppointment?uniquename=${doctorId}`, { headers: authHeader() });
const deleteAppointment = (userId, appointmentId) => axios.delete(`${API_URL}api/v1/users/${userId}/appointments/${appointmentId}`, { headers: authHeader() });
const getPatientforDoctor = (appointmentId,userId) => axios.get(`${API_URL}restrictedArea/getBasicProfile/${appointmentId}/${userId}`, { headers: authHeader() });
export default {
  getDoctors,
  getDoctor,
  getPatientAppointments,
  getdrAppointment,
  bookAppointment,
  deleteAppointment,
  getPatientforDoctor,
};
