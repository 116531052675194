import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { data } from 'jquery';
import { getdr } from '../actions/drsearch';
const Drlist = () => {
    var flagD = 1;
    var dtMain;
    const { user: currentUser } = useSelector(state => state.auth);
    // console.log("DRSEARC",drsearch)
    const { message } = useSelector(state => state.message);
    const { drlist } = useSelector(state => state.drsearch);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [drsearch, setDrsearch] = useState([]);
    var offset = 10;
    useEffect(() => {
        componentWillMount();
        if (drlist.length == 0) {
            window.$('#searchHeaderMain').hide();
            window.$('#drlistmain').css('margin-top', '30px');

            console.log("SUccess1", drsearch.length)
            flagD = 1;
            // var filters = localStorage.getItem('filter');
            offset = 0;
            loadDrList();
            // console.log(filters);
            // if (!!filters) {
            //     filter =JSON.parse(filters);
            // }

        }
        else {
            setDrsearch(drlist);
        }
    }, []);
    const loadDrList = () => {
        debugger;
        var filter = {};
        var drSearchF = {
            "filter": filter,
            "containingText": { "doctor_name": "" },
            "sort": { "isAssociated": "-1" },
            "pagination": { "pageSize": 10, "offset": offset }
        }
        setLoading(true);
        dispatch(getdr(drSearchF))
            .then((response) => {
                setDrsearch(oldDrlist => [...oldDrlist, ...response]);
                console.log("response", drsearch)
                //setSuccessful(true);
                //setLoading(false);
            })
            .catch(() => {
                console.log("error1")
                setLoading(false);
            });
        offset += 10;
        window.$("#hSearch").attr("href", "/");
    }
    function componentWillMount() {
        window.addEventListener('scroll', loadMore);
    }

    function componentWillUnmount() {
        window.removeEventListener('scroll', loadMore);
    }

    function loadMore(e) {

        console.log('top', e.target.documentElement.scrollTop);
        console.log('win', window.innerHeight);
        console.log('height', e.target.scrollingElement.scrollHeight);
        debugger;
        if ((window.innerHeight + e.target.documentElement.scrollTop + 1) > e.target.scrollingElement.scrollHeight) {
            // Do load more content here!
            loadDrList();
            console.log(e)
        }
    }
    const bookAppointment = (drname) => {
        console.log(drname)
    };
    // if (!currentUser) {
    //     return <Redirect to="/" />;
    // }
    // if (successful) {
    //     return <Redirect to="/dr" />;
    //   }
    const doctorsList = drsearch.map(item => (

        <div className="card p-3 drlistrow mb-3">
            <div className="d-flex align-items-center">
                <div className="image"> <img src={!!item.googleInfo.image ? item.googleInfo.image : "assets/img/doctors/doctor.png"} className="rounded" width="155" /> </div>
                <div className="ms-3 w-100">
                    {/* <a href="#"> <h4 className="mb-0 mt-0">{item.doctor_name}</h4></a> <span>{item.specialty_and_non_popular_services==null?"":item.specialty_and_non_popular_services.map(value => `${value}`).join(',')}</span> */}
                    <a href={"/drdetails/" + item.uniquename}> <h4 className="mb-0 mt-0">{item.doctor_name}</h4></a> <span style={{ textTransform: 'capitalize' }}>{item.specialty_and_non_popular_services == null ? "" : item.specialty_and_non_popular_services[0].split('_')[1]}</span>
                    <div>
                        <a href="#" className="address">{item.practice.city == "" ? "None" : item.practice.city}</a>
                        {/* : <a href="#" className="clinic">{item.googleInfo.title} </a>
                        <a href="#" className="clinic">+ 2 more</a> */}
                    </div>
                    <div className="p-2 mt-2 bg-info d-flex justify-content-between rounded text-white stats">
                        <div className="d-flex flex-column"> <span className="articles">Experience</span> <span className="number1">{item.experience_years == "" ? 0 : item.experience_years} Years</span> </div>
                        <div className="d-flex flex-column"> <span className="followers">Fees</span> <span className="number2">₹{item.consultation_fees == "" ? 0 : item.consultation_fees} </span> </div>
                        <div className="d-flex flex-column"> <span className="rating">Rating</span> <span className="number3">{item.googleInfo.overallRating}</span> </div>
                    </div>
                    <div className="button mt-2 d-flex flex-row align-items-center"> <a href={"/drdetails/" + item.uniquename} className="btn btn-sm btn-outline-primary w-100"><i className="bi bi-eye-fill  me-2"></i>View Profile</a></div>
                </div>
            </div>
        </div>

    ))

    if (doctorsList.length == 0 && flagD == 1) {
        dtMain = 'Please Wait Some Times.';
    }
    else if (doctorsList.length == 0 && flagD == 0) {
        dtMain = "Please input Your search criteria to get your desired search result."
    }
    else {
        dtMain = '';
    }
    console.log("FLAG", flagD)
    return (
        <section>
            {/* {loading && */}

            {/* <div>
                <span className="spinner-border spinner-border-lg" />
            </div> */}
            {/* } */}
            <div className='container drlistmain' id="drlistmain">
                <div className="row mt-2">
                    {/* <div className="col-md-2">
                        {/* <Sidenavdash /> 
                    </div> */}

                    {
                        dtMain.length === 0 ? <div className="col-md-8"> {doctorsList} </div> : <div className="col-md-8 text-center">{dtMain}</div>

                    }


                    <div className="col-md-4">
                    </div>
                </div>
            </div>
        </section>

    )
}


export default Drlist