import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import { getdrdetails, getdr, saveRating, getComments, saveComments, addIntimate, claimProfile } from '../actions/drsearch';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Patient from '../services/patient.service';
import { Rating } from 'react-simple-star-rating'
import * as moment from 'moment';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import DatePicker from 'react-datepicker';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const ScanBookDrDetails = (props) => {
    var flagD = 0;
    var claimDoctor = 0;
    const [drdetails, setContent] = useState('');
    const [rating, setRating] = useState(0)
    const { user: currentUser } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const { drlist: drsearch } = useSelector(state => state.drsearch);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [successful, setSuccessful] = useState(false);
    const [drs, setdrs] = useState([]);
    const MySwal = withReactContent(Swal)
    const { id, ct } = useParams();
    const [comment, setComment] = useState('');
    const [comments, getComment] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const handleRating = (rate) => {
        rate = rate;
        setRating(rate)
        // other 
        if (currentUser) {
            const ratingJson = { "rating": rate / 20 }
            saveRating(id, ratingJson)
                .then((res) => {

                    MySwal.fire({
                        title: <strong>Rating Updated!</strong>,
                        icon: 'success'
                    })
                });
        } else {
            MySwal.fire({
                title: <strong>Please Login</strong>,
                icon: 'warning'
            })
        }

    }
    useEffect(() => {
        debugger;
        console.log('hshdsd', id);
        window.$('#mainmobileNavbar').addClass('d-none');
        window.$('#scanmobileNavbar').removeClass('d-none');
        window.$('#scanmobileNavbar').attr("onclick","$('#btnbookApp').click();");
        setLoading(true);
        if (drsearch.length != 0) {
            const drdata = drsearch.filter(it => it.uniquename === id)
            if (drdata.length > 0) {
                setdrs(drdata);
                getdrdetails(id)
                .then((res) => {
                    setContent(res.data);
                    // flagD = 1;
                });
            getComments(id)
                .then((res) => {
                    setLoading(false);
                    getComment(res.data.results);

                });
            }
            console.log("drsearch", drsearch);
            // setRating({drs[0].googleInfo.overallRating});
        }
        else {
            var drSea = {
                "filter": { "uniquename": id },
                "containingText": {
                    "doctor_name": ''

                },
                "sort": {
                    "fee": "1"
                }
            }
            dispatch(getdr(drSea))
                .then((res) => {
                    console.log("drsearch", res);
                    getdrdetails(id)
                    .then((res) => {
                        setContent(res.data);
                        // flagD = 1;
                    });
                getComments(id)
                    .then((res) => {
                        setLoading(false);
                        getComment(res.data.results);
    
                    });
                })
                .catch((ex) => {
                    setLoading(false);
                });
            const drdata = drsearch.filter(it => it.uniquename === id);

            if (drdata.length > 0) {
                setdrs(drdata);
            }
            // setRating(drs[0].googleInfo.overallRating);

        }
       

    }, [drsearch]);

    const saveComment = () => {
        setLoading(true);
        const commentJson = { "comment": comment }
        saveComments(id, commentJson)
            .then((res) => {
                MySwal.fire({
                    title: <strong>Comment Updated!</strong>,
                    icon: 'success'
                })
                getComments(id)
                    .then((res) => {
                        setLoading(false);
                        getComment(res.data.results);

                    });
            });
    }
    const bookAppointment = (drname, flag) => {

        if (currentUser && flag) {
            setLoading(true);
            Patient.bookAppointment(drname).then((res) => {
                if (res.data.data.toString().includes("not available")) {
                    alert(res.data.data)
                }
                else {
                    var orderId = res.data.data.orderId;
                    var txnToken = res.data.data.txnToken;
                    pay(orderId, txnToken);
                }

            })
        }
        else if (currentUser && !flag) {
            console.log("Video", flag)
        }
        else {
            window.$('#modalLRForm').modal('toggle');
        }
    };
    const addIntimates = () => {
        setLoading(true);
        addIntimate(id).then((res) => {
            setLoading(false);
            MySwal.fire({
                title: <strong>Thank you for your precious feedback!</strong>,
                icon: 'success'
            })
        });
    };
    const claimProfiles = () => {
        claimDoctor = 1;
        if (currentUser) {
            window.$('#verifyModal').modal('toggle');

        }
        else {
            window.$('#modalLRForm').modal('toggle');
            $('.register').removeClass('d-none');
            $('.login').addClass('d-none');
        }
        // setLoading(true);
        // claimProfile(id).then((res) => {
        //     setLoading(false);
        //     MySwal.fire({
        //         title: <strong>Thank you for your precious feedback!</strong>,
        //         icon: 'success'
        //     })
        // });
    };
    const pay = (orderId, token) => {

        var config = {
            "root": "",
            "flow": "DEFAULT",
            "data": {
                "orderId": orderId, //"61d72168bc52d863733a8bc7", /* update order id */
                "token": token,//3df3a45995a24451ba2aa71558e359801641488745759", /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": "1.00" /* update amount */
            },
            "handler": {
                "notifyMerchant": function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                    setLoading(false);
                }
            }
        };

        if (window.Paytm && window.Paytm.CheckoutJS) {
            setLoading(true);
            // window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            // initialze configuration using init method 
            console.log("config", config);
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout
                debugger;
                console.log("Sucess", config);
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("error => ", error);
            });
            // });
        }
    }

    const allComments = comments && comments.map(itemC => (
        <div className="comment-widgets m-b-20 shadow-sm  bg-body mb-1">
            <div className="d-flex flex-row comment-row px-3 py-3 border border-ligh border-1">
                <div className="p-2"><span className="round"><img className='rounded-circle' src="https://i.imgur.com/uIgDDDd.jpg" alt="user" width="50" /></span></div>
                <div className="comment-text w-100">
                    <h5>{itemC.user_id}</h5>
                    <div className="comment-footer"> <span className="date"> {moment(itemC.date).format('DD-MM-YYYY, h:mm a')}</span>
                        {/* <span className="action-icons"> <a href="#" data-abc="true"><i className="fa fa-pencil"></i></a> <a href="#" data-abc="true"><i className="fa fa-rotate-right"></i></a> <a href="#" data-abc="true"><i className="fa fa-heart"></i></a> </span> */}
                    </div>
                    <p className="m-b-5 m-t-10">{itemC.comment}</p>
                </div>
            </div>
        </div>
    ))
    const doctorsList = !!drdetails && !!drs && drdetails.map(item => (

        <div>
            <div className='mt-2 d-flex drlistrow d-none'>
                <a href='/' className='mx-1 address' >Home</a> /<a href="/dr" className="address mx-1">Find A doctor</a>
                {/* /<a href='#' className='mx-1 address' >{drs[0].doctor_name}</a>  */}
            </div>
            <div className="row mt-2">
                <div className="col-md-8">
                    <div className="card p-3 drlistrow mb-3">
                        <div className="d-flex">

                            <div className="me-3 w-100">
                                {/* <a href="#"> <h4 className="mb-0 mt-0">{item.doctor_name}</h4></a> <span>{item.specialty_and_non_popular_services==null?"":item.specialty_and_non_popular_services.map(value => `${value}`).join(',')}</span> */}
                                <a href="#"> <h4 className="mb-0 mt-0">{!!drs[0] ? drs[0].doctor_name : ""}</h4></a>
                                <span>{item["specializations"] == null ? "" : item["specializations"][0]}</span>
                                <span className="mx-3">|</span>
                                <span className="number1">{!!drs[0] & !!drs[0].experience_years ? drs[0].experience_years : 0} Years</span>
                                <span className="mx-3">|</span>
                                <span className="number2">₹{!!drs[0] & drs[0].consultation_fees == "" ? 0 : drs[0].consultation_fees} </span>
                                <div>
                                    <a href="#" className="address">{!!drs[0] & drs[0].practice.city == "" ? "None" : drs[0].practice.city}</a> : <a href="#" className="clinic">{!!item.clinics ? item.clinics[0].location : ""}</a>
                                    {/* <a href="#" className="clinic">+ 2 more</a> */}
                                </div>
                                <div>
                                    Rating:<span title="Practo Rating" id='ratingid' data-toggle="tooltip" data-placement="bottom">{drs[0].googleInfo.overallRating}</span> <Rating onClick={handleRating} ratingValue={rating} initialValue={drs[0].googleInfo.overallRating} size={20}/* Available Props */ />

                                </div>
                                <div className='d-none'>
                                    Other Ratings:
                                    <span className="mx-1" title="Practo Rating">Practo: {drs[0].googleInfo["www.practo.com.rating"]} <i className="bi bi-star-fill ms-1 text-success"></i></span>|
                                    <span className="mx-1" title="Justdial Rating">Justdial: {drs[0].googleInfo["www.justdial.com.rating"]} <i className="bi bi-star-fill ms-1 text-success"></i></span>|
                                    <span className="mx-1" title="More" id="btnMore"><a href='#' onClick={(id) => {
                                        if ($('#moreRating').hasClass('d-none')) {
                                            window.$('#moreRating').removeClass('d-none');
                                            window.$(id.target)[0].innerText = "- Less";
                                        }
                                        else {
                                            window.$('#moreRating').addClass('d-none');
                                            window.$(id.target)[0].innerText = "+ 4 more";
                                        }

                                    }}>+ 4 more</a></span>
                                    <div className='d-none' id='moreRating' style={{ 'marginLeft': '6.5rem' }}>
                                        <span className="mx-1" title="Logintohealth Rating">Logintohealth: {drs[0].googleInfo["www.logintohealth.com.rating"]} <i className="bi bi-star-fill ms-1 text-success"></i></span>
                                        <span className="mx-1" title="Drlogy Rating">Drlogy: {drs[0].googleInfo["drlogy.com.rating"]} <i className="bi bi-star-fill ms-1 text-success"></i></span>|
                                        <span className="mx-1" title="Credihealth Rating">Credihealth: {drs[0].googleInfo["www.credihealth.com.rating"]} <i className="bi bi-star-fill ms-1 text-success"></i></span>|
                                        <span className="mx-1" title="Doctor360 Rating">Doctor360: {drs[0].googleInfo["doctor360.rating"]} <i className="bi bi-star-fill ms-1 text-success"></i></span>
                                    </div>
                                </div>
                                {/* <div className="button mt-2 d-flex flex-row align-items-center"> <button className="btn btn-sm btn-outline-primary w-100"><i className="bi bi-camera-video-fill  me-2"></i>Video Consult</button> <button type="submit" className="btn btn-sm btn-primary w-100 ms-2" onClick={() => this.bookAppointment(this, item.translated_new_slug, localStorage.access_token)}>Book Appointment</button> </div> */}
                            </div>
                            <div className="image"> <img src={!!drs[0].googleInfo.image ? drs[0].googleInfo.image : "assets/img/doctors/doctor.png"} className="rounded" style={{'width':'100px','height':'100px'}} /> </div>
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Info</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Comments</button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Contact</button>
                        </li> */}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div>
                                    <div className="work-container pt-4">
                                        <h6 className='text-primary '>Detailed Experience</h6>
                                        {item["experience"] && item["experience"].map(sp => (
                                            <p>{sp}</p>
                                        ))}
                                    </div>
                                    <div className="work-container pt-4">
                                        <h6 className='text-primary '>Educations</h6>
                                        {item["educations"] && item["educations"].map(sp => (
                                            <p>{sp}</p>
                                        ))}
                                    </div>
                                    <div className="work-container pt-4">
                                        <h6 className='text-primary '>Memberships</h6>
                                        {item["memberships"] && item["memberships"].map(sp => (
                                            <p>{sp}</p>
                                        ))}
                                    </div>
                                    <div className="work-container pt-4">
                                        <h6 className='text-primary '>Doctor Certification</h6>
                                        {item["training_certification"] && item["training_certification"].map(sp => (
                                            <p>{sp}</p>
                                        ))}
                                    </div>
                                    <div className="work-container pt-4">
                                        <h6 className='text-primary '>Doctor Registrations</h6>
                                        {item["registrations"] && item["registrations"].map(sp => (
                                            <p>{sp}</p>
                                        ))}
                                    </div>
                                    <div className="work-container pt-4">
                                        <h6 className='text-primary '>Doctor Awards</h6>
                                        {item["awards"] && item["awards"].map(sp => (
                                            <p>{sp}</p>
                                        ))}
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="justify-content-center mt-5 border-left border-right">
                                    {currentUser ? <div className="d-flex justify-content-center pt-3 pb-2"> <input type="text" name="text" placeholder="+ Add a note" className="form-control addtxt" onChange={(e) => setComment(e.target.value)} /><button onClick={saveComment} className="btn btn-sm btn-primary ms-2">Submit</button> </div> : ""}

                                    <div className="col-md-12">
                                        <div className="card rounded">
                                            <div className="card-body">
                                                <h4 className="card-title">Recent Comments</h4>
                                                <h6 className="card-subtitle">Latest Comments section by users</h6>
                                            </div>
                                            {allComments.length > 0 ? allComments : <h4 className='ms-3'>{"Login to see comments"}</h4>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div> */}
                        </div>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-3 drlistrow mb-3">
                        <div className="work-container pt-4">
                            <h6 className='text-primary '>Clinic Address</h6>
                            <hr />
                            <button id='btnbookApp' type="button" className="btn btn-sm btn-primary w-100 ms-2 d-none" onClick={() => bookAppointment(item.uniquename, true)}>Book Appointment</button>
                            {item["clinics"] && item["clinics"].map(sp => (
                                <div className='row'>
                                    <div className='col'>
                                        <span>{sp.location}</span>
                                        <a href="#"> <h5 className="mb-0 mt-0">{sp.name}</h5></a>
                                        <div className='d-flex'>
                                            <span className="">Rating :</span>
                                            <a href="#" className='clinic ms-3'>{sp.rating}<i className="bi bi-star-fill ms-1 text-success"></i></a><br />
                                            <span className="mx-3">|</span>
                                            <span className="">Fee :</span>
                                            <span>{sp.fee}</span>
                                        </div>
                                        <span>{sp.address}</span><br />
                                        <a href={sp.directions} target={'_blank'}>Map Location</a>


                                        {sp.timings && sp.timings.map(tm => (
                                            <div className='mt-2'>
                                                <h6 className='mb-0'>{Object.keys(tm)[0]}</h6>
                                                <span>{tm[Object.keys(tm)[0]].map(fr => (
                                                    fr.from + "-" + fr.to
                                                ))}</span>
                                            </div>
                                        ))
                                        }

                                    </div>
                                    {item.isAssociated ?
                                        <div className="button my-3 d-flex flex-row align-items-center"> <button className="btn btn-sm btn-outline-primary w-100" onClick={() => bookAppointment(item.uniquename, false)}><i className="bi bi-camera-video-fill  me-2"></i>Video Consult</button> <button type="button" className="btn btn-sm btn-primary w-100 ms-2" onClick={() => bookAppointment(item.uniquename, true)}>Book Appointment</button> </div>
                                        : ""}<hr />
                                    {/* <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        locale="pt-BR"
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                        inline
                                    /> */}
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div >
            <div className='row mt-2'>
                <div className='col-md-12'>
                    <div className='work-container pt-4'>
                        <h5>If you like the qualifications and experience of {drs[0].doctor_name}, would you consider consultation</h5>
                        <div className="button my-3 d-flex flex-row align-items-center" style={{ "left": "30%", "position": "absolute" }}>
                            <button type="button" className="btn btn-sm btn-primary me-2 px-4" onClick={addIntimates}>Yes</button>
                            <button className="btn btn-sm btn-outline-primary  px-4 " onClick={() => MySwal.fire({
                                title: <strong>Thank you for your precious feedback!</strong>,
                                icon: 'success'
                            })}>No</button></div>
                    </div>
                </div>
            </div>
            {!item.isAssociated ?
                <div className='row mt-2 d-none'>
                    <div className='col-md-12'>
                        <div className='work-container pt-5'>
                            <h5>If you are {drs[0].doctor_name}, please claim your profile to be onboarded onto our platform</h5>
                            <div className="button my-3 d-flex flex-row align-items-center" style={{ "left": "30%", "position": "absolute" }}>
                                <button type="button" className="btn btn-sm btn-primary me-2 px-4" onClick={claimProfiles}>Claim</button>
                            </div>
                        </div>
                    </div>
                </div> : ""}
        </div>
        
    ))

    var dtMain;
    if (drdetails.length === 0 && flagD === 1) {
        dtMain = "We are in process of getting doctors onboard, Please change your seacrh filter."
    }
    else if (drdetails.length === 0 && flagD === 0) {
        dtMain = "Please Seacrh Your Doctors With your search above criteria."
    }
    else {
        dtMain = '';
    }
    return (
        <section className='pt-3'>
            {/* {loading && */}

            {/* <div>
                <span className="spinner-border spinner-border-lg" />
            </div> */}
            {/* } */}
            <div className='container' style={{'minHeight': '800px' }}>
                <div>
                    {/* <div className="col-md-2">
                        {/* <Sidenavdash /> 
                    </div> */}
                    {/* <div className="col-md-8"> */}
                    {

                        dtMain.length === 0 ? doctorsList : dtMain

                    }

                    {/* </div>
                    <div className="col-md-4">
                    </div> */}
                </div>
            </div>
            <div className={"overlay " + (loading ? '' : 'd-none')}>
                <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
            </div>
        </section>

    )
}


export default ScanBookDrDetails