import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/auth';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
// import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const Header = () => {
    const tabs = [{
        route: "/home",
        icon: "<i class='bx bxs-home' style={{ 'font-size': '20px' }}></i>",
        label: "Home"
    }, {
        route: "/search",
        icon: "<i class='bx bx-search' style={{ 'font-size': '20px' }}></i>",
        label: "Search"
    }, {
        route: "/login",
        icon: "<i class='bx bx-user-circle' style={{ 'font-size': '20px' }}></i>",
        label: "Login"
    }]

    const { user: currentUser, isDoctor: isDoctor } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const logOut = () => {
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        dispatch(logout(user))
            .then((data) => {
                setLoading(false);
                // console.log("Profile", data);
            })
            .catch(() => {
                setLoading(false);
            });

    };
    var userprofile = JSON.parse(localStorage.getItem("profile"));
    var userP = JSON.parse(localStorage.getItem("user"));
    //console.log("isDoctor", userP)
    return (
        <div>
            <header id="header" class="fixed-top">
                <div className={"overlay " + (loading ? '' : 'd-none')}>
                    <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
                </div>
                <div class="container d-flex align-items-center">

                    <h1 class="logo me-auto text-Colored"><a href="/">GetMeHeal</a></h1>

                    <nav id="navbar" class="navbar order-last order-lg-0 d-none d-lg-block">
                        {!currentUser ?
                            <ul>
                                {/* <li><a class="nav-link scrollto" href="#doctors"><div>About Us</div></a></li> */}
                                <li><a class="nav-link scrollto" id="hSearch" href="/"><div>Find a Doctor</div> </a></li> {/*  <div className="subtitle">Doctor/Clinic/Hospital</div> <div className="subtitle">Doctor/Clinic/Hospital</div><div className="subtitle">Online / Clinic visit</div>*/}
                                <li><a class="nav-link scrollto" href='/adddoctor' id='adddoctor' type='button'><div>Add a Doctor</div> </a></li>
                                {/* <li><a class="nav-link scrollto" href="#doctors"><div>Compare Cost</div> <div className="subtitle">Diagnosys & Medicine</div></a></li> */}
                                <li><a class="nav-link scrollto" href="/#appointment"><div> Make an Appointment</div> </a></li>
                                {/* <li><a class="nav-link scrollto" href="#doctors"><div>Contact Us</div></a></li> */}
                                <li><a class="nav-link scrollto" id="hHelp" href="/#contact"><div>Help</div></a></li>
                                <a href="" class="appointment-btn seachHeadermain" type="button" data-bs-toggle="modal" data-bs-target="#modalLRForm"><span class="d-md-inline">Login /</span> Signup</a>
                            </ul>

                            :
                            <ul>
                                <li><a class="nav-link scrollto mr-2" id="hSearch" href="/" style={{ 'marginRight': '10px' }}><div>Find a Doctor</div></a></li>
                                <li><a class="nav-link scrollto" href='/adddoctor' id='adddoctor' type='button'><div>Add a Doctor</div> </a></li>
                                {userprofile && userprofile.role === "user" ? <li><a class="nav-link scrollto mr-2" href="/appointments" style={{ 'marginRight': '10px' }}><div>My Appointments</div></a></li> : <li><a class="nav-link scrollto mr-2" href="/drappointments" style={{ 'marginRight': '10px' }}><div>My Appointments</div></a></li>}
                                <li className="dropdown seachHeadermain"><a href="#" className="d-flex"><i className="bx bx-user-circle me-1" style={{ 'font-size': '30px' }}></i>{userprofile ? userprofile.username : ''}<i className="bi bi-chevron-down"></i></a>
                                    <ul>
                                        <li>  {userprofile && userprofile.role === "user" ? <a href="/profile"><i className='bx bx-cog nav_icon' style={{ 'font-size': '20px' }}></i> <span>Profile</span></a> : <a href="/drprofile"><i className='bx bx-cog nav_icon' style={{ 'font-size': '20px' }}></i> <span>Profile</span></a>}</li>

                                        <li><a href="/#contact"><i className='bx bx-mail-send' style={{ 'font-size': '20px' }}></i>Support</a></li>
                                        <li><a onClick={logOut}><i className='bx bx-log-out' style={{ 'font-size': '20px' }}></i>Logout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        }
                        <i class="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    <nav id="mobileNavbar" class="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav">
                        {!currentUser ?
                            <ul class="w-100 nav">
                                {/* <li><a class="nav-link scrollto" href="#doctors"><div>About Us</div></a></li> */}
                                <div className=" d-flex flex-row justify-content-around w-100" id='mainmobileNavbar'>
                                    <li class="nav-item">
                                        <a class="nav-link bottom-nav-link" id="hSearch" href="/">
                                            <i class='bx bx-search' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">a Doctor</div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link bottom-nav-link" href='/adddoctor' id='adddoctor' type='button'>
                                            <i class='bx bx-plus-circle' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">a Doctor</div>
                                        </a>
                                    </li>
                                    {/* <li class="nav-item">
                                        <a class="nav-link bottom-nav-link" href="/#appointment">
                                            <i class='bx bx-search' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">Consult for Free</div>
                                        </a>
                                    </li> */}
                                    <li class="nav-item">
                                        <a class="nav-link bottom-nav-link" href="/#contact" id="hHelp">
                                            <i class='bx bxs-help-circle' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">Help</div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="" class="nav-link bottom-nav-link" type="button" data-bs-toggle="modal" data-bs-target="#modalLRForm">

                                            <i class='bx bx-user-circle' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">Login</div>
                                        </a>
                                    </li>
                                </div>
                                <button class="btn btn-primary col-12 mx-auto d-none" type="button" id='scanmobileNavbar'>Book</button>
                                {/* <a href="" class="appointment-btn seachHeadermain" type="button" data-bs-toggle="modal" data-bs-target="#modalLRForm"><span class="d-md-inline">Login /</span> Signup</a> */}
                            </ul>

                            :
                            <ul class="w-100 nav">
                                <div className=" d-flex flex-row justify-content-around w-100" id='mainmobileNavbar'>
                                    {/*  <div className="subtitle">Doctor/Clinic/Hospital</div> <div className="subtitle">Doctor/Clinic/Hospital</div><div className="subtitle">Online / Clinic visit</div>*/}
                                    <li class="nav-item">
                                        <a class="nav-link bottom-nav-link" id="hSearch" href="/">
                                            <i class='bx bx-search' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">a Doctor</div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link bottom-nav-link" href='/adddoctor' id='adddoctor' type='button'>
                                            <i class='bx bx-plus-circle' style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">a Doctor</div>
                                        </a>
                                    </li>
                                    {userprofile && userprofile.role === "user" ?
                                        <li class="nav-item">
                                            <a class="nav-link bottom-nav-link" href="/appointments">
                                                <i class='bx bx-shekel' style={{ 'font-size': '20px' }}></i>
                                                <div className="bottom-tab-label">Appointments</div>
                                            </a>
                                        </li> :
                                        <li class="nav-item">
                                            <a class="nav-link bottom-nav-link" href="/drappointments">
                                                <i class='bx bx-shekel' style={{ 'font-size': '20px' }}></i>
                                                <div className="bottom-tab-label">Appointments</div>
                                            </a>
                                        </li>}
                                    <li className="nav-item dropdown">
                                        <a href="#" className="nav-link bottom-nav-link">
                                            <i className="bx bx-user-circle" style={{ 'font-size': '20px' }}></i>
                                            <div className="bottom-tab-label">{userprofile ? userprofile.username : ''}</div>
                                            {/* <i className="bi bi-chevron-down"></i> */}
                                        </a>
                                        <ul className="dropdown-menu-top" >
                                            <li> <a href="/profile"><i className='bx bx-cog nav_icon' style={{ 'font-size': '20px' }}></i> <span>Profile</span></a></li>

                                            <li><a href="/#contact"><i className='bx bx-mail-send' style={{ 'font-size': '20px' }}></i>Support</a></li>
                                            <li><a onClick={logOut}><i className='bx bx-log-out' style={{ 'font-size': '20px' }}></i>Logout</a></li>
                                        </ul>
                                    </li>
                                </div>
                                <button class="btn btn-primary col-12 mx-auto d-none" type="button" id='scanmobileNavbar'>Book Appointment</button>
                            </ul>
                        }

                    </nav>
                </div>
            </header >

        </div >
    )
}
export default Header