import React from 'react';

import { Helmet } from "react-helmet";
export default () => {
  return (<div className="application">
    <Helmet>
      <link href="../assets/vendor/animate.css/animate.min.css" rel="stylesheet" />
      <link href="../assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
      <link href="../assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet" />
      <link href="../assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet" />
      <link href="../assets/vendor/fontawesome-free/css/all.min.css" rel="stylesheet" />
      <link href="../assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet" />
      <link href="../assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
      <link href="../assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />
      <link href="https://code.jquery.com/ui/1.13.0/themes/smoothness/jquery-ui.css" rel="Stylesheet" />
      {/* <link href="../assets/css/yu.scss" rel="stylesheet" /> */}


      <link href="../assets/css/style.css" rel="stylesheet" />
      {/* <script src='assets/vendor/jquery/jquery-3.3.1.slim.min.js'></script>
   */}
      <script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
      <script src="../assets/vendor/swiper/swiper-bundle.min.js"></script>
      <script src="../assets/vendor/bootstrap/js/bootstrap.min.js"></script>
      <script src="../assets/vendor/glightbox/js/glightbox.min.js"></script>
      <script src="../assets/vendor/php-email-form/validate.js"></script>
      <script src="../assets/vendor/purecounter/purecounter.js"></script>
      <script src="https://code.jquery.com/ui/1.13.0/jquery-ui.js" integrity="sha256-xH4q8N0pEzrZMaRmd7gQVcTZiFei+HfRTBPJ1OGXC0k=" crossorigin="anonymous"></script>

      <script src="../assets/js/main.js"></script>
      <script type="application/javascript" crossorigin="anonymous" src="https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/rYnLhs35260591458979.js"> </script>
      <script crossorigin src="https://unpkg.com/@daily-co/daily-js"></script>
    </Helmet>
  </div>)
}
