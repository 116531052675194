import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://easycure.onrender.com/restrictedArea/';

const getProfile = () => axios.get(`${API_URL}getProfile`, { headers: authHeader() });
const getDoctor = id => axios.get(`${API_URL}api/v1/doctors/${id}`, { headers: authHeader() });
const getAppointments = id => axios.get(`${API_URL}api/v1/users/${id}/appointments`, { headers: authHeader() });
const getAppointment = (userId, appointmentId) => axios.get(`${API_URL}api/v1/users/${userId}/appointments/${appointmentId}`, { headers: authHeader() });
const postAppointment = (userId, doctorId, appointmentDate) => axios.post(`${API_URL}api/v1/users/${userId}/appointments`, { doctor_id: doctorId, appointment_date: appointmentDate }, { headers: authHeader() });
const deleteAppointment = (userId, appointmentId) => axios.delete(`${API_URL}api/v1/users/${userId}/appointments/${appointmentId}`, { headers: authHeader() });
const savePrescription = (dr, appointment_id, prescription) => axios.post(`${API_URL}${dr}/prescription/${appointment_id}`, prescription, { headers: authHeader() });
const getPrescription = (dr, appointment_id, prescription_id) => axios.get(`${API_URL}${dr}/prescription/${appointment_id}/${prescription_id}`, { headers: authHeader() });
export default {
  getProfile,
  getDoctor,
  getAppointments,
  getAppointment,
  postAppointment,
  deleteAppointment,
  savePrescription,
  getPrescription,
};
