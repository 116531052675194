import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
export default class patientprofile extends React.Component {

    constructor(props) {
        super(props)
        this.state = { user: {} }
    }
    componentDidMount() {

    }
    // getProfile = (e, token) => {
    //     //e.preventDefault()
    //     axios.defaults.headers.common['Authorization'] = token;
    //     axios.get("https://easycure.herokuapp.com/restrictedArea/getProfile")
    //         .then(res => {
    //             this.setState({ user: res.data.data });
    //             console.log("Profile Data", res);
    //         })
    // }
    render() {
        const { user } = this.state;
        var userprofile = JSON.parse(localStorage.getItem("profile"));
        console.log("Profile data", userprofile);
        return (

            <>
                <div className="row pt-5">

                    <div className='col-md-12' style={{ 'padding': '0 15%' }}>
                        <div className="shadow-lg bg-white rounded form-group row justify-content-center">
                            <div class="card-header">
                                Patient Profile
                            </div>
                            <div className="px-4 pb-5 align-items-center col-md-12 text-center">

                                {/* <p>Start with your free account today.</p> */}
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="First name" required value={userprofile && userprofile.name?userprofile.name:''} />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Middle name" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Last name" />
                                    </div>
                                </div>
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Date of birth" required />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Age" />
                                    </div>
                                    <div className="col">
                                        {/* <input type="text" className="form-control" placeholder="Gender" /> */}
                                        <select className="form-control" >
                                            <option value="male">Male</option>
                                            <option value="female">FeMale</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row form-group mt-3">
                                    <div className="col  d-none">
                                        <input type="text" className="form-control" placeholder="Weight" />
                                    </div>
                                    <div className="col">
                                        <input type="text" min="0" maxLength="13" className="form-control" placeholder="Mobile Number" value={userprofile && userprofile.mobile?userprofile.mobile:''} />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Email Address" value={userprofile && userprofile.email?userprofile.email:''} />
                                    </div>
                                </div>
                                <div className="row form-group mt-3 d-none">
                                    <div className="col">
                                        <select className="form-control">
                                            <option>Select Symptoms </option>
                                            <option>Fever</option>
                                            <option>Cough</option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="From When" />

                                    </div>
                                    {/* <div className="col">
                            <input type="text" className="form-control" placeholder="LinkedIn Profile" />
                        </div> */}
                                </div>
                                {/* <div className="row form-group mt-3">
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Instagram" />
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Twitter Profile" />
                        </div>
                        <div className="col">
                        </div>
                    </div> */}
                                <div className="row form-group mt-4 d-none">
                                    <div className="col">
                                        <textarea type="text" className="form-control" placeholder="Free Text about me (Visiting Faculty, Award etc.)" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="shadow-lg bg-white rounded form-group row justify-content-center mt-5">
                            <div class="card-header">
                                Address
                            </div>
                            <div className="px-4 pb-5 align-items-center col-md-12 text-center">
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Address 1" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Address 2" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Address 3" />
                                    </div>
                                </div>
                                <div className="row form-group mt-4">
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="Location" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="City" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control" placeholder="State" />
                                    </div>
                                </div>
                                <div className="row form-group mt-4">
                                    <div className="col">
                                        <select className="form-control" placeholder="Country" >
                                            <option>India</option>
                                            <option>USA</option>
                                            <option>AUS</option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <input type="number" className="form-control" placeholder="Pincode" min="0" />
                                    </div>
                                </div>
                                <div className="d-grid gap-2 col-6 mx-auto mt-3"><button type="submit" className="btn btn-primary py-2 w-100">Update Profile</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
