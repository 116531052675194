import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import user from './user';
import drsearch from './drsearch';

export default combineReducers({
  auth,
  message,
  user,
  drsearch,
});
