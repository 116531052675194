import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { login, register } from '../actions/auth';
import { getProfile } from '../actions/user';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { verificationData } from '../actions/drsearch';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
    return '';
};

const Login = () => {
    const MySwal = withReactContent(Swal);
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState('');
    const [isdoctor, setIsdoctor] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const alert = useAlert();
    const dispatch = useDispatch();
    const profiles = JSON.parse(localStorage.getItem('profile'));
    const onChangeEmail = e => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangeisdoctor = e => {
        const isdoctor = e.target.checked;
        console.log(isdoctor)
        setIsdoctor(isdoctor);
        if (isdoctor) {
            window.$("#name").attr("type", "email");
            window.$("#name").attr("placeholder", "Username");
        }
        else {
            window.$("#name").attr("type", "text");
            window.$("#name").attr("placeholder", "Email");
        }
    };
    const onChangePassword = e => {
        const password = e.target.value;
        setPassword(password);
    };
    const onChangeMobile = e => {
        const mobile = e.target.value;
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            setMobile(mobile);
        }
    };
    const handleLogin = e => {
        e.preventDefault();
        setLoading(true);
        // form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            var status = '';
            if (isdoctor) {
                status = 'doctor';
            }
            else {
                status = 'user';
            }
            dispatch(login(email, password, status))
                .then(() => {
                    profile();
                    debugger;
                    window.$('#modalLRForm').modal('hide');
                    window.$('.modal-backdrop').hide();
                    window.$('body').css('overflow', '');
                    if (!status) {
                        return <Navigate to="/drappointments" />;
                    }
                    // setLoading(false);
                    // alert.show('You are logged in', {
                    //     type: 'success',
                    //     timeout: 5000,
                    // });
                })
                .catch((ex) => {

                    MySwal.fire({
                        title: <strong>{ex.data}</strong>,
                        icon: 'error'
                    })
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };
    const registerpatient = e => {
        e.preventDefault();
        setLoading(true);
        var status = '';
        if (isdoctor) {
            status = 'doctor';
        }
        else {
            status = 'user';
        }
        var data = {
            "username": email,
            "password": password,
            "email": email,
            "mobile": mobile,
            "role": status // can be user, admin, doctor
        }
        debugger;
        dispatch(register(data))
            .then((res) => {
                console.log(res);
                MySwal.fire({
                    title: <strong>{res.message}</strong>,
                    icon: 'success',
                    timer: 2000
                })
                setLoading(false);
                if (res.message.search('successful')) {
                    dispatch(login(email, password, ""))
                        .then(() => {
                            profile();
                            debugger;
                            window.$('#modalLRForm').modal('hide');
                            window.$('.modal-backdrop').hide();
                            window.$('body').css('overflow', '');
                            if (!status) {
                                return <Navigate to="/drappointments" />;
                            }
                            // setLoading(false);
                            // alert.show('You are logged in', {
                            //     type: 'success',
                            //     timeout: 5000,
                            // });
                        })
                        .catch((ex) => {

                            MySwal.fire({
                                title: <strong>{ex.data}</strong>,
                                icon: 'error'
                            })
                            setLoading(false);
                        });
                }
                //window.location.reload();
                //window.$('#modalLRForm').modal('toggle');
            })
            .catch((ex) => {

                MySwal.fire({
                    title: <strong>{ex.message}</strong>,
                    icon: 'error'
                })
                setLoading(false);
            });
    }
    const profile = () => {
        dispatch(getProfile())
            .then((data) => {
                setLoading(false);
                window.$('body').css('overflow', '');
                console.log("Profile", data);
            })
            .catch(() => {
                setLoading(false);
            });
    }
    // if (isLoggedIn) {
    //     return <Redirect to="/dr" />;
    // }
    const claimProfiles = (evnt) => {
        debugger;
        evnt.preventDefault()
        setLoading(true);
        let formData = new FormData();
        formData.append("registrationDocs", evnt.target.registrationDocs.files[0]);
        formData.append("registrationNo", evnt.target.registrationNo.value);
        formData.append("user", evnt.target.username.value);
        formData.append("ph", evnt.target.ph.value);
        formData.append("email", evnt.target.email.value);
        verificationData(formData).then((resv) => {
            setLoading(false);
            MySwal.fire({
                title: <strong>We will verify your profile and will notify you soon.</strong>,
                icon: 'success'
            }).then(function () {
                window.location.reload();
            });

        }).catch((ex) => {
            setLoading(false);
        });
    };
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '17px';
        }
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.paddingRight = '0px';
        };
    }, [show]);
    // setShow(true);
    return (

        <div className="col-md-12">
            <div className="card card-container">
                <div className={"overlay " + (loading ? '' : 'd-none')}>
                    <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
                </div>

                <div className="modal fade" id="modalLRForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content login">
                            <div className="modal-header">
                                <h3 className="modal-title w-100 dark-grey-text font-weight-bold" id="myModalLabel"><strong>Sign in</strong></h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Form onSubmit={handleLogin} ref={form}>
                                    <div className="d-flex flex-column text-center">

                                        <div className="form-group mb-3">
                                            <input type="text" className="form-control" id="email1" placeholder="Mobile Number / Email ID" value={email}
                                                onChange={onChangeEmail}
                                                validations={[required]} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input type="password" className="form-control" id="password1" placeholder="Password" value={password}
                                                onChange={onChangePassword}
                                                validations={[required]} required />
                                        </div>
                                        {/* <div className="form-check  mb-3" style={{ 'textAlign': 'left' }}>
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={onChangeisdoctor} />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Are You Doctor?
                                            </label>
                                        </div> */}
                                        <button type="submit" className="btn btn-info btn-block btn-round appointment-btn" style={{ width: '100%', 'marginLeft': 'unset' }}>Login</button>


                                        {/* <div className="text-center text-muted delimiter">or use a social network</div>
                                        <div className="d-flex justify-content-center social-buttons">
                                            <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Twitter">
                                                <i className="fab fa-twitter"></i>
                                            </button>
                                            <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Facebook">
                                                <i className="fab fa-facebook"></i>
                                            </button>
                                            <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Linkedin">
                                                <i className="fab fa-linkedin"></i>
                                            </button>
                                        </div> */}
                                    </div>
                                    <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                                </Form>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                                <div className="signup-section">Not a member yet? <a href="#" className="text-info" id="signup" type="button"> Sign Up</a>.</div>
                            </div>
                        </div>
                        <div className="modal-content d-none register">
                            <div className="modal-header">
                                <h3 className="modal-title w-100 dark-grey-text font-weight-bold" id="myModalLabel"><strong>Join GetMeHeal</strong></h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Form onSubmit={registerpatient} ref={form}>
                                    <div className="d-flex flex-column text-center">
                                        <div className="form-group mb-3">
                                            <input type="email" className="form-control" id="name" placeholder="Email"
                                                value={email}
                                                onChange={onChangeEmail} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            {/* <Input className="form-control" id="mobile" placeholder="Mobile Number" validations={[required]}></Input> */}
                                            <input type="text" className="form-control" id="mobile" placeholder="Mobile Number" maxLength={10}
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                value={mobile}
                                                onChange={onChangeMobile} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input type="password" className="form-control" id="cpassword1" placeholder="Password"
                                                value={password}
                                                onChange={onChangePassword} required />
                                        </div>
                                        <div className="form-check  mb-3" style={{ 'textAlign': 'left' }}>
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" onChange={onChangeisdoctor} />
                                            <label className="form-check-label" for="flexCheckDefault1">
                                                Are You Doctor?
                                            </label>
                                        </div>
                                        <button type="submit" className="btn btn-info btn-block btn-round appointment-btn" style={{ width: '100%', 'marginLeft': 'unset' }}>Register</button>
                                        {/* <div className="text-center text-muted delimiter">or use a social network</div>
                                        <div className="d-flex justify-content-center social-buttons">
                                            <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Twitter">
                                                <i className="fab fa-twitter"></i>
                                            </button>
                                            <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Facebook">
                                                <i className="fab fa-facebook"></i>
                                            </button>
                                            <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Linkedin">
                                                <i className="fab fa-linkedin"></i>
                                            </button>
                                        </div> */}
                                    </div>
                                </Form>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                                <div className="signup-section">Already member? <a href="#" className="text-info" id="signin"> Sign In</a>.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="verifyModal" tabindex="-1" aria-labelledby="verifyModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="verifyModalLabel">Verification Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form className="requires-validation" novalidate onSubmit={claimProfiles} id="msform">
                                    <div className="col-md-12 mb-3">
                                        <input className="form-control" type="text" name="username" value={!!profiles ? profiles.username : ''} placeholder="Username" disabled />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <input className="form-control" type="email" name="email" value={!!profiles ? profiles.email : ''} placeholder="E-mail Address" disabled />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <input className="form-control" type="text" name="ph" value={!!profiles ? profiles.mobile : ''} disabled />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {/* <label for="registrationDocs" class="form-label">Registration Docs</label> */}
                                        <input class="form-control" type="file" id="registrationDocs" name='registrationDocs' />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <input className="form-control" type="text" name="registrationNo" placeholder="Registration No" />
                                    </div>
                                    <div className="form-check text-start">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                                        <label className="form-check-label">I confirm that all data are correct</label>
                                        <div className="invalid-feedback">Please confirm that the entered data are all correct!</div>
                                    </div>
                                    <div className="form-button mt-3 text-center">
                                        <button id="submit" type="submit" className="btn btn-primary">Verify</button>
                                    </div>
                                </form>
                            </div>
                            {/* <div class="modal-footer">

                            </div> */}
                        </div>
                    </div>
                </div>
                {/* <div className="form-group">
                        <button className="btn btn-primary btn-block" disabled={loading} type="submit">
                            {loading && (
                                <span className="spinner-border spinner-border-sm" />
                            )}
                            <span>Login</span>
                        </button>
                    </div> */}
                {/* {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )} */}


            </div>
        </div>
    );
};

export default Login;
