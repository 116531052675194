import React from 'react';
export default (params) => {
  return (
    <div>
      <footer id="footer" className=''>
        <div class="footer-top d-none">
          <div class="container">
            <div class="row">

              <div class="col-lg-3 col-md-6 footer-contact">
                <h3>Medilab</h3>
                <p>
                  A108 Adam Street <br />
                  New York, NY 535022<br />
                  United States <br /><br />
                  <strong>Phone:</strong> +1 5589 55488 55<br />
                  <strong>Email:</strong> info@example.com<br />
                </p>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>Join Our Newsletter</h4>
                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                <form action="" method="post">
                  <input type="email" name="email" /><input type="submit" value="Subscribe" />
                </form>
              </div>

            </div>
          </div>
        </div>

        <div class="container d-md-flex pt-4">

          <div class="me-md-auto text-center text-md-start">
            <div class="copyright">
              &copy; Copyright <strong><span>GetMeHeal</span></strong>. All Rights Reserved
            </div>
            {/* <div class="credits">

              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div> */}
          </div>
         
            <div class="me-md-auto text-center text-md-start ">
              <div class="copyright d-flex">
              {/* {params.IsContact ? */}
                <a class="scrollto me-4" href="/aboutus"><div>About Us </div></a>
                <a class="scrollto me-4" href="/#contact"><div>Contact Us </div></a>
                <a class="scrollto me-4" href="/terms"><div>T&C</div></a>
                <a class="scrollto me-4" href="/refundpolicy"><div>Refund Policy</div></a>
              {/*} <a class="scrollto" href="#doctors"><div>Contact Us</div></a> */}
              </div>

            </div>
          <div class="social-links text-center text-md-right pt-3 pt-md-0">
            <a target="_blank" href="https://twitter.com/intent/tweet?text=https%3A//www.getmeheal.com/" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A//www.getmeheal.com/" class="facebook"><i class="bx bxl-facebook"></i></a>
            {/* <a target="_blank" href="https://www.instagram.com/?url=https%3A//www.getmeheal.com/" class="instagram"><i class="bx bxl-instagram"></i></a> */}
            <a target="_blank" href="whatsapp://send?text=https%3A//www.getmeheal.com/" class="google-plus"><i class="bx bxl-whatsapp"></i></a>
            <a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.getmeheal.com/&title=GetMeHeal%20is%20India's%201st%20healthcare%20portal%20which%20has%20growing%20database%20of%20doctors&summary=&source=" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
        </div>
        <div class="container py-4">
        <p style={{ 'color': 'black', 'fontSize': '12px' }}>Disclaimer: GetMeHeal is a rapidly growing centralized database of doctors, clinics and hospitals. We have combined data from different sources on GetMeHeal. Accuracy of the profile information depends upon source accuracy. Please update your profile for better accuracy or inform us if you do not want your profile on our platform.</p>
        </div>
      </footer>

      <div id="preloader"></div>
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </div>)
}