/* eslint-disable no-console */
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './types';

import AuthService from '../services/auth.service';

// eslint-disable-next-line max-len
export const register = (data) => dispatch => AuthService.register(data).then(
  response => {
    // if (!!response.data.message) {
    //   dispatch({
    //     type: REGISTER_FAIL,
    //   });
    //   return Promise.reject(response.data);

    // }
    // else {
    //   console.log("Register", response.data)
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: response.data },
      });
    //}
    // dispatch({
    //   type: SET_MESSAGE,
    //   payload: response.data.message,
    // });

    return Promise.resolve(response.data);
  },
  error => {
    console.log(error.response);
    const message = (error.response
      && error.response.data
      && error.response.data.message)
      || error.message
      || error.toString();
    // const message = error.response.data.errors[0];
    dispatch({
      type: REGISTER_FAIL,
    });

    // dispatch({
    //   type: SET_MESSAGE,
    //   payload: message,
    // });

    return Promise.reject();
  },
);


export const login = (email, password, role) => dispatch => AuthService.login(email, password, role).then(
  data => {
    // data.isdoctor=role

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: data },
    });

    return Promise.resolve();
  },
  error => {
    const message = (error.response
      && error.response.data
      && error.response.data.message)
      || error.message
      || error.toString();
    dispatch({
      type: LOGIN_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject(error.response);
  },
);

export const logout = (user) => dispatch =>
  AuthService.logout(user).then(
    data => {
      // data.isdoctor=role
      dispatch({
        type: LOGOUT,
      });
      return Promise.resolve();
    }, error => {
      return Promise.reject();
    });
export const verifypayment = (id) =>
  AuthService.verifypayment(id).then(
    data => {
      // data.isdoctor=role
      return data.data;
    }, error => {
      return error;
    });
