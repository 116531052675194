import logo from './logo.svg';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import Script from './component/scripts';
import Headers from './component/header';
import Slider from './component/slider';
import Whyus from './component/whyus';
import Aboutus from './component/aboutus';
import Services from './component/services';
import Appointment from './component/appointment'
import MainAboutUs from './component/MainAboutUs'
import Contact from './component/contact';
import Footer from './component/footer';
import Login from './component/Login';
import { clearMessage } from './actions/message';
import Drlist from './component/drlist';
import Drdetails from './component/drdetails';
import myappointment from './component/dashboard/myappointment';
import drappointment from './component/doctor/drappointments';
import SeacrhHeader from './component/searchheader';
import Callback from './component/paytmcallback'
import patientprofile from './component/dashboard/patientprofile';
import prescriptionbuilder from './component/doctor/prescriptionbuilder';
import viewprescription from './component/dashboard/viewprescription';
import Adddoctorhospital from './component/doctor/adddoctorhospital';
import TermsCondition from './component/termconditions';
import RefundPolicy from './component/refundpolicy';
import Drprofile from './component/doctor/drprofile';
import Scanbook from './component/scanbook';
import Routes from './routes';
const OtherComponent = lazy(() => import('./component/dashboard/myappointment'));
const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        dispatch(clearMessage()); // clear message when changing location
    }, [dispatch, location]);
    return (
        // <div>

        //     <Script></Script>
        //     <Headers Login={true} IsLogin={true}></Headers>
        //     <Login></Login>

        //     <main className='pt-5'>

        //         <Switch>
        //             <Route exact path="/">
        //                 <SeacrhHeader></SeacrhHeader>
        //                 <Whyus></Whyus>
        //                 <Aboutus></Aboutus>
        //                 <Appointment></Appointment>
        //                 <Contact></Contact>
        //             </Route>
        //             <Route exact path="/dr">
        //                 <SeacrhHeader></SeacrhHeader>
        //                 <Drlist></Drlist>
        //             </Route>
        //             <Route exact path="/drdetails/:id">
        //                 <SeacrhHeader />
        //                 <Drdetails />
        //             </Route>
        //             <Route path="/callback/:id" component={Callback} />
        //             <Route exact path="/appointments" component={myappointment} />
        //             <Route exact path="/drappointments" component={drappointment} />
        //             <Route exact path="/profile" component={patientprofile} />
        //             <Route exact path="/drprofile" component={Drprofile} />
        //             <Route exact path="/prescriptionbuilder/:uname" component={prescriptionbuilder} />
        //             <Route exact path="/viewprescription/:uname" component={viewprescription} />
        //             <Route exact path="/aboutus">
        //                 <MainAboutUs></MainAboutUs>
        //             </Route>
        //             <Route exact path="/adddoctor">
        //                 <Adddoctorhospital></Adddoctorhospital>
        //             </Route>
        //             <Route exact path="/terms" component={TermsCondition}></Route>
        //             <Route exact path="/refundpolicy" component={RefundPolicy}></Route>
        //             <Route exact path="/qrbooking/:id" component={Scanbook}></Route>
        //         </Switch>

        //     </main>
        //     <Footer IsContact={true}></Footer>

        // </div>
        <Routes />
    );
}

export default App;
