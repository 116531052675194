import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getStats } from '../actions/drsearch';
// import { data } from 'jquery';

const RefundPolicy = (props) => {
    const [stats, getStat] = useState('');


    return (

        <div>
            <section id="about" class="about">
                <div class="container">
                    <div class="section-title"><h2>Refund Policy</h2></div>
                    <div class="row">
                        <h5>At GetMeHeal, we value our customer's experience. We want to ensure that you are fully satisfied with our services and get best in class experience while using our services. Our refund and cancellation policy is to maximizing customer satisfaction and inline with Face to Face consultation practices</h5>
                        <h5><b>1. No show by doctor:</b> In case, if the Doctor with whom Patient has booked a paid appointment via Website / smartphone Application, has not been able to meet the patient, patient will automatically get a full refund within seven (7) business days. </h5>
                        <h5>2. In the above case, entire consultation amount as mentioned on the Website / Smartphone application, will be automatically refunded to the patient within the next seven (7) business days in the original mode of payment done by the patient while booking. Patient might also be offered another opportunity of consultation, which will be subject to acceptance by patient at the cost of payment made earlier.</h5>
                        <h5>3. If any discrepancy arises during refund or compensatory appointment, we request our esteemed customers to inform us at <a href = "mailto: support@getmeheal.com">support@getmeheal.com</a>, we will do the needful.</h5>
                        <h5><b>4. No Show by Patient:</b> In case where patient has not shown up for the appointment booked with a Doctor, without cancelling the appointment beforehand, the amount will not be refunded. However, where cancellation charges have been levied (as charged by the Doctor), you would not be entitled to complete refund even if you have cancelled beforehand.</h5>
                        <h5>5. Patient will not be entitled for any refunds in cases where, the Doctor is unable to meet the patient at the exact time of the scheduled appointment time and the patient has been informed / intimated to wait.</h5>
                        <h5>6. Exceptional cases will be handled by GetMeHeal on merit basis and will be decided to maximize customer satisfaction. We encourage our customers to write us at <a href = "mailto: support@getmeheal.com">support@getmeheal.com</a>, their experience and issues in detail, to help us in understanding and resolving them to maximize customer satisfaction.</h5>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default RefundPolicy
