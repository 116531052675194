import React, { useEffect, useState } from 'react';
import { updateDrProfile, verificationData, getdrdetails, getdr } from '../../actions/drsearch';
// import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { logout } from '../../actions/auth';
import HashLoader from "react-spinners/HashLoader";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
export default (params) => {
    var userprofile = JSON.parse(localStorage.getItem("profile"));
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [drdetails, setContent] = useState('');
    const [drs, setdrs] = useState('');
    const { drlist: drsearch } = useSelector(state => state.drsearch);
    var drprofile = {
        "doctor_name": "",
        "practice": {
            "city": ""
        },
        "googleInfo": {
            "ph": "",
            "email": ""
        },
        "specialty_and_non_popular_services": [],
        "doctor-specializations": []
    }
    const [data, setData] = useState(drprofile);
    useEffect(() => {
        var drSea = {
            "filter": { "uniquename": userprofile.username },
            "containingText": {
                "doctor_name": ''

            },
            "sort": {
                "fee": "1"
            }
        }
        dispatch(getdr(drSea))
            .then((res) => {
                const drdata = res.filter(it => it.uniquename === userprofile.username)
                setdrs(drdata);
                const rowsInput = data;
                let rowsInputs = Object.assign(rowsInput, drdata[0])
                setData(rowsInputs);
                console.log("combind 1", data);
            })
            .catch((ex) => {
                setLoading(false);
            });

        getdrdetails(userprofile.username)
            .then((res) => {
                setContent(res.data);
                debugger;
                const rowsInput = data;
                let rowsInputs = Object.assign(rowsInput, res.data[0])
                setData(rowsInputs);
                console.log("combind", data);
                // flagD = 1;
            });
        var elems = document.querySelector(".active");
        if (elems !== null) {
            elems.classList.remove("active");
        }
        window.$('#adddoctor').addClass('active');
        window.$("#adddocter").attr("href", "#");
    }, []);

    const handleChange = (evnt) => {
        debugger;
        const { name, value } = evnt.target;
        const rowsInput = data;
        //console.log(rowsInput);
        var nmt = name.split('.')
        var ty = nmt[1];
        if (nmt.length = 2 && !!ty) {
            if (ty == 'array') {
                rowsInput[nmt[0]] = value.split(',');
            }
            else {
                rowsInput[nmt[0]][ty] = value;
            }
        }
        else {

            rowsInput[name] = value;
        }
        setData(rowsInput);
    }
    const updateDoctors = (evnt) => {
        debugger;
        evnt.preventDefault()

        setLoading(true);
        updateDrProfile(data).then((es) => {
            let formData = new FormData();
            formData.append("registrationDocs", evnt.target.registrationDocs.files[0]);
            formData.append("registrationNo", data.registrationNo);
            formData.append("user", data.doctor_name);
            formData.append("ph", data.googleInfo.ph);
            formData.append("email", data.googleInfo.email);
            verificationData(formData).then((resv) => {
                setLoading(false);
                MySwal.fire({
                    title: <strong>Profile Updated</strong>,
                    text: 'We will verify your profile and will notify you soon.',
                    icon: 'success'
                }).then((result) => {
                    window.location.reload();
                })
            }).catch((ex) => {
                setLoading(false);
            });

        }).catch((ex) => {
            setLoading(false);
        });


    }
    return (
        <div className='pt-5'>
            <div className={"overlay " + (loading ? '' : 'd-none')}>
                <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
            </div>
            <div className="row pt-5" style={{ 'width': '100%' }}>
                <div className='col-md-12' style={{ 'padding': '0 15%' }}>
                    <form className="requires-validation" novalidate onSubmit={updateDoctors} id="updateDr">
                        <div className="shadow-lg bg-white rounded form-group row justify-content-center">
                            <div class="card-header">
                                Your Profile
                            </div>
                            <div className="px-4 pb-5 align-items-center col-md-12 text-center">

                                {/* <p>Start with your free account today.</p> */}
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input className="form-control" type="text" name="doctor_name" placeholder="Enter Doctor / Hospital Name" required
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drsearch.length > 0 ? drsearch[0].doctor_name : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="uniquename" placeholder="UserName" required
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drsearch.length > 0 ? drsearch[0].uniquename : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="email" name="googleInfo.email" placeholder="E-mail Address"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={userprofile && userprofile.email ? userprofile.email : ''} />
                                    </div>

                                </div>
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input className="form-control phonenumber" type="text" name="googleInfo.ph" placeholder="Phone / Mobile No"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={userprofile && userprofile.mobile ? userprofile.mobile : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="practice.city" placeholder="Enter City" required
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drsearch.length > 0 ? drsearch[0].practice.city : ''} />
                                    </div>

                                    <div className="col d-flex">
                                        <input className="form-control me-2" type="text" name="experience_years" placeholder="Total Expiernce" required
                                            onChange={(evnt) => (handleChange(evnt))}
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" defaultValue={drsearch.length > 0 ? drsearch[0].experience_years : 0} />
                                        <input className="form-control" type="text" name="fee" placeholder="Fees" required
                                            onChange={(evnt) => (handleChange(evnt))}
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" defaultValue={drsearch.length > 0 ? drsearch[0].consultation_fees : 0} />
                                    </div>
                                </div>


                                <div className="row form-group mt-4 d-none">
                                    <div className="col">
                                        <textarea type="text" className="form-control" placeholder="Free Text about me (Visiting Faculty, Award etc.)" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="shadow-lg bg-white rounded form-group row justify-content-center mt-4">
                            <div class="card-header">
                                Professional Details
                            </div>
                            <div className="px-4 pb-5 align-items-center col-md-12 text-center">
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input className="form-control" type="text" name="specialty_and_non_popular_services.array" placeholder="Specialty With Commas" required
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drsearch.length > 0 && drsearch[0].specialty_and_non_popular_services != null ? drsearch[0].specialty_and_non_popular_services.join(',') : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="specializations.array" placeholder="Specializations With Commas" required
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].specializations ? drdetails[0].specializations.join(',') : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="awards.array" placeholder="Awards"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].awards ? drdetails[0].awards.join(',') : ''} />
                                    </div>
                                </div>
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input className="form-control" type="text" name="educations.array" placeholder="Educations"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].educations ? drdetails[0].educations.join(',') : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="memberships.array" placeholder="Memberships"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].memberships ? drdetails[0].memberships.join(',') : ''} />

                                    </div>
                                    <div className='col'>
                                        <input className="form-control" type="text" name="experience.array" placeholder="Expiernces"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].experience ? drdetails[0].experience.join(',') : ''} />
                                    </div>
                                </div>
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input className="form-control" type="text" name="registrations.array" placeholder="Registrations"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].registrations ? drdetails[0].registrations.join(',') : ''} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="training_certification.array" placeholder="Training Certification"
                                            onChange={(evnt) => (handleChange(evnt))} defaultValue={drdetails.length > 0 && drdetails[0].training_certification ? drdetails[0].training_certification.join(',') : ''} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-lg bg-white rounded form-group row justify-content-center mt-5">
                            <div class="card-header">
                                Verification Details
                            </div>
                            <div className="px-4 pb-5 align-items-center col-md-12 text-center">
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <input class="form-control" type="file" id="registrationDocs" name='registrationDocs' />
                                    </div>
                                    <div className="col">
                                        <input className="form-control" type="text" name="registrationNo" placeholder="Registration No" />
                                    </div>

                                </div>
                                {/* <div className="row form-group mt-3">
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Address 1" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Address 2" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Address 3" />
                                </div>
                            </div>
                            <div className="row form-group mt-4">
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="Location" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="City" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="State" />
                                </div>
                            </div>
                            <div className="row form-group mt-4">
                                <div className="col">
                                    <select className="form-control" placeholder="Country" >
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>AUS</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <input type="number" className="form-control" placeholder="Pincode" min="0" />
                                </div>
                            </div> */}
                                <div className="d-grid gap-2 col-6 mx-auto mt-3"><button type="submit" className="btn btn-primary py-2 w-100">Update Profile</button></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
