import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getStats } from '../actions/drsearch';
// import { data } from 'jquery';

const TermsCondition = (props) => {
    const [stats, getStat] = useState('');


    return (

        <div>
            <section id="about" className="about">
                <div className="container">
                    <div className="section-title"><h2>Terms & Conditions</h2></div>
                    <div className="row">
                        <h5>Technovalty Consulting Solutions LLP, on behalf of itself and its affiliates/group companies under the brand "GetMeHeal" (“GetMeHeal”), is the author and publisher of the internet resource www.getmeheal.com and the mobile application ‘GetMeHeal’ (together, “Website”). GetMeHeal owns and operates the services provided through the Website.</h5>
                        <h5>By accessing, using, downloading our Service and Website, you indicate to have read, understood, and agree to be bound by these Terms of Use (Terms) and all other applicable laws and regulations, without any limitation or qualification.</h5>
                    </div>
                    <div className="section-title mt-4"><h5>THIS IS A LEGAL AGREEMENT BETWEEN YOU AND GETMEHEAL, AND IT GOVERNS YOUR USE OF OUR SERVICES.</h5></div>
                    <div className="section-title"><h2>1.NATURE AND APPLICABILITY OF TERMS</h2></div>
                    <div className="row">
                        <h5>"Please carefully go through these terms and conditions (“Terms”) and the privacy policy available at https://www.getmeheal.com before you decide to access the Website or avail the services made available on the Website by GetMeHeal. These Terms and the Privacy Policy together constitute a legal agreement (“Agreement”) between you and GetMeHeal in connection with your visit to the Website and your use of the Services (as defined below).</h5>
                        <h5><b>The Agreement applies to you whether you are -</b></h5>
                        <h5>  a. A medical practitioner or health care provider (whether an individual professional or an organization) or similar institution wishing to be listed, or already listed, on the Website, including designated, authorized associates of such Doctor or institutions (“Practitioner(s)”, "Doctor", “you”, or “User”); or</h5>
                        <h5>b. A patient, his/her representatives or affiliates, searching for Doctor through the Website (“End-User”, “you”, "patient" or “User”); or</h5>
                        <h5>c. Otherwise a user of the Website (“you” or “User”).</h5>
                        <h5><b>This Agreement applies to those services made available by GetMeHeal on the Website, which are offered free of charge or through payment to the Users (“Services”), including the following:</b></h5>
                        <h5>"a. For Doctors: (i) Listing of doctors and their profiles and contact details, to be made available to the other Users and visitors to the Website, (ii) Facility to create and maintain "Accounts"</h5>
                        <h5>b. For other Users, patients: Facility to (i) create and maintain "Accounts"</h5>
                        <h5>c. Search for doctors by name, specialty, and geographical area, or any other criteria that may be developed and made available by GetMeHeal, and </h5>
                        <h5>d. To make appointments with doctors"</h5>
                        <h5>The Services may change from time to time, at the sole discretion of GetMeHeal, and the Agreement will apply to your visit and your use of the Website to avail the Service, as well as to all information provided by you on the Website at any given point in time.</h5>
                        <h5>This Agreement defines the terms and conditions under which you are allowed to use the Website and describes the manner in which we shall treat your account while you are registered as a member with us. If you have any questions about any part of the Agreement, feel free to contact us at <a href="mailto: support@getmeheal.com">support@getmeheal.com</a></h5>
                        <h5>By downloading or accessing the Website to use the Services, you irrevocably accept all the conditions stipulated in this Agreement, the Subscription Terms of Service and Privacy Policy, as available on the Website, and agree to abide by them. This Agreement supersedes all previous oral and written terms and conditions (if any) communicated to you relating to your use of the Website to avail the Services. By availing any Service, you signify your acceptance of the terms of this Agreement.</h5>
                        <h5>"We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time, and such modifications shall be informed to you in writing You should read the Agreement at regular intervals. Your use of the Website following any such modification constitutes your agreement to follow and be bound by the Agreement so modified.
                            You acknowledge that you will be bound by this Agreement for availing any of the Services offered by us. If you do not agree with any part of the Agreement, please do not use the Website or avail any Services.Your access to use of the Website and the Services will be solely at the discretion of GetMeHeal."</h5>
                        <h5>
                            <b>The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but not limited to:</b>
                            <h5>a. The Indian Contract Act, 1872,</h5>
                            <h5>b. The (Indian) Information Technology Act, 2000, and</h5>
                            <h5>c. The rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”), and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 (the “IG Rules”).</h5>

                        </h5>
                    </div>
                    <div className="section-title mt-4"><h2>2.CONDITIONS OF USE</h2></div>
                    <div className="row">
                        <h5>You must be 18 years of age or older to register, use the Services, or visit or use the Website in any manner. By registering, visiting and using the Website or accepting this Agreement, you represent and warrant to GetMeHeal that you are 18 years of age or older, and that you have the right, authority and capacity to use the Website and the Services available through the Website, and agree to and abide by this Agreement.</h5>
                    </div>
                    <div className="section-title mt-4"><h2>3.TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN Doctor</h2></div>
                    <div className="row">
                        <h5>The terms in this clause 3 are applicable only to Users other than Doctor.
                            <br />
                            <br />
                            <b>3.1 END-USER ACCOUNT AND DATA PRIVACY</b></h5>
                        <div className='ms-4' >
                            <h5>
                                3.1.1 The terms “personal information” and “sensitive personal data or information” are defined under the SPI Rules, and are reproduced in the Privacy Policy.
                            </h5>
                            <h5>
                                3.1.2 GetMeHeal may by its Services, collect information relating to the devices through which you access the Website, and anonymous data of your usage. The collected information will be used only for improving the quality of GetMeHeal’s services and to build new services.
                            </h5>
                            <h5>
                                3.1.3 The Website allows GetMeHeal to have access to registered Users’ personal email or phone number, for communication purpose so as to provide you a better way of booking appointments and for obtaining feedback in relation to the Doctor and their practice.
                            </h5>
                            <h5>
                                3.1.4 The Privacy Policy sets out, inter-alia:
                            </h5>
                            <div className='ms-5'>
                                <h5>
                                    a. The type of information collected from Users, including sensitive personal data or information;
                                </h5>
                                <h5>
                                    b. The purpose, means and modes of usage of such information; How and to whom GetMeHeal will disclose such information; and,
                                </h5>
                                <h5>
                                    c. Other information mandated by the SPI Rules.
                                </h5>
                            </div>
                            <h5>
                                3.1.5 The User is expected to read and understand the Privacy Policy, so as to ensure that he or she has the knowledge of, inter-alia:
                            </h5>
                            <div className='ms-5'>
                                <h5>
                                    a. the fact that certain information is being collected;
                                </h5>
                                <h5>
                                    b. the purpose for which the information is being collected;
                                </h5>
                                <h5>
                                    c. the intended recipients of the information;
                                </h5>
                                <h5>
                                    d. the nature of collection and retention of the information; and
                                </h5>
                                <h5>
                                    e. the name and address of the agency that is collecting the information and the agency that will retain the information; and
                                </h5>
                                <h5>
                                    f. the various rights available to such Users in respect of such information.
                                </h5>
                            </div>
                            <h5>
                                3.1.6 GetMeHeal shall not be responsible in any manner for the authenticity of the personal information or sensitive personal data or information supplied by the User to GetMeHeal or to any other person acting on behalf of GetMeHeal.
                            </h5>
                            <h5>
                                3.1.7 The User is responsible for maintaining the confidentiality of the User’s account access information and password, if the User is registered on the Website. The User shall be responsible for all usage of the User’s account and password, whether or not authorized by the User. The User shall immediately notify GetMeHeal of any actual or suspected unauthorized use of the User’s account or password. Although GetMeHeal will not be liable for your losses caused by any unauthorized use of your account, you may be liable for the losses of GetMeHeal or such other parties as the case may be, due to any unauthorized use of your account.
                            </h5>
                            <h5>
                                3.1.8 If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or GetMeHeal has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, GetMeHeal has the right to discontinue the Services to the User at its sole discretion.
                            </h5>
                            <h5>
                                3.1.9 GetMeHeal may use such information collected from the Users from time to time for the purposes of debugging customer support related issues.
                            </h5>
                        </div>
                        <h5>
                            3.2 NO DOCTOR-PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE
                        </h5>
                        <div className='ms-4'>
                            <h5>
                                3.2.1 Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, “Information”) that may be available on the Website (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between GetMeHeal and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified practitioner.
                            </h5>
                            <h5>
                                3.2.2 It is hereby expressly clarified that, the Information that you obtain or receive from GetMeHeal, and its employees, contractors, partners, sponsors, advertisers, censors or otherwise on the Website is for informational purposes only. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the Website. In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information.
                            </h5>
                            <h5>
                                3.2.3 The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are an End-User facing a medical emergency (either on your or a another person’s behalf), please contact an ambulance service or hospital directly.
                            </h5>
                        </div>
                    </div>
                    <div className="section-title mt-4"><h2>4. TERMS OF USE DOCTORS</h2></div>
                    <div className='row'>
                        <h5><b>The terms in this Clause 4 are applicable only to Doctor.</b></h5>
                        <h5>4.1 LISTING POLICY</h5>
                        <div className='ms-4' >
                            <h5>4.1.1 GetMeHeal, directly and indirectly, collects information regarding the Doctor profiles, contact details, and practice. GetMeHeal reserves the right to take down any Practitioner’s profile as well as the right to display the profile of the Doctor, with or without notice to the concerned Practitioner. This information is collected for the purpose of facilitating interaction with the End-Users and other Users. If any information displayed on the Website in connection with you and your profile is found to be incorrect, you are required to inform GetMeHeal immediately to enable GetMeHeal to make the necessary amendments.</h5>
                            <h5>4.1.2 GetMeHeal shall not be liable and responsible for the ranking of the Doctor on external websites and search engines</h5>
                            <h5>4.1.3 GetMeHeal shall not be responsible or liable in any manner to the Users for any losses, damage, injuries or expenses incurred by the Users as a result of any disclosures or publications made by GetMeHeal, where the User has expressly or implicitly consented to the making of disclosures or publications by GetMeHeal. If the User had revoked such consent under the terms of the Privacy Policy, then GetMeHeal shall not be responsible or liable in any manner to the User for any losses, damage, injuries or expenses incurred by the User as a result of any disclosures made by GetMeHeal prior to its actual receipt of such revocation.</h5>
                            <h5>4.1.4 GetMeHeal reserves the right to moderate the suggestions made by the Doctor through feedback and the right to remove any abusive or inappropriate or promotional content added on the Website. However, GetMeHeal shall not be liable if any inactive, inaccurate, fraudulent, or non- existent profiles of Doctor are added to the Website.</h5>
                            <h5>4.1.5 Doctos explicitly agree that GetMeHeal reserves the right to publish the Content provided by Doctor to a third party including content platforms.</h5>
                            <h5>4.1.6 You as a Practitioner hereby represent and warrant that you will use the Services in accordance with applicable law. Any contravention of applicable law as a result of your use of these Services is your sole responsibility, and GetMeHeal accepts no liability for the same.</h5>
                        </div>
                        <h5>
                            4.2 PROFILE OWNERSHIP AND EDITING RIGHTS</h5>
                        <h5 className='ms-4'>
                            GetMeHeal ensures easy access to the Practitioners by providing a tool to update your profile information. GetMeHeal reserves the right of ownership of all the Practitioner’s profile and photographs and to moderate the changes or updates requested by Practitioners. However, GetMeHeal takes the independent decision whether to publish or reject the requests submitted for the respective changes or updates. You hereby represent and warrant that you are fully entitled under law to upload all content uploaded by you as part of your profile or otherwise while using GetMeHeal’s services, and that no such content breaches any third party rights, including intellectual property rights. Upon becoming aware of a breach of the foregoing representation, GetMeHeal may modify or delete parts of your profile information at its sole discretion with or without notice to you.
                        </h5>
                        <h5>4.3 REVIEWS AND FEEDBACK DISPLAY RIGHTS OF GetMeHeal</h5>
                        <div className='ms-4'>
                            <h5>
                                4.3.1 All Critical Content is created by the Users of www.GetMeHeal.com (“Website”) and the clients of GetMeHeal customers and Practitioners, including the End-Users. As a platform, GetMeHeal does not take responsibility for Critical Content and its role with respect to Critical Content is restricted to that of an ‘intermediary’ under the Information Technology Act, 2000. The role of GetMeHeal and other legal rights and obligations relating to the Critical Content are further detailed in Clauses 3.9 and 5 of these Terms. GetMeHeal’s Feedback Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and remains subject always to these Terms.
                            </h5>
                            <h5>
                                4.3.2 GetMeHeal reserves the right to collect feedback and Critical Content for all the Practitioners, Clinics and Healthcare Providers listed on the Website.
                            </h5>
                            <h5>
                                4.3.3 GetMeHeal shall have no obligation to pre-screen, review, flag, filter, modify, refuse or remove any or all Critical Content from any Service, except as required by applicable law.
                            </h5>
                            <h5>
                                4.3.4 You understand that by using the Services you may be exposed to Critical Content or other content that you may find offensive or objectionable. GetMeHeal shall not be liable for any effect on Practitioner’s business due to Critical Content of a negative nature. In these respects, you may use the Service at your own risk. GetMeHeal however, as an ‘intermediary, takes steps as required to comply with applicable law as regards the publication of Critical Content. The legal rights and obligations with respect to Critical Content and any other information sought to be published by Users are further detailed in Clauses 3.9 and 5 of these Terms.
                            </h5><h5>
                                4.3.5 GetMeHeal will take down information under standards consistent with applicable law, and shall in no circumstances be liable or responsible for Critical Content, which has been created by the Users. The principles set out in relation to third party content in the terms of Service for the Website shall be applicable mutatis mutandis in relation to Critical Content posted on the Website.
                            </h5><h5>
                                4.3.6 If GetMeHeal determines that you have provided inaccurate information or enabled fraudulent feedback, GetMeHeal reserves the right to immediately suspend any of your accounts with GetMeHeal and makes such declaration on the website alongside your name/your clinics name as determined by GetMeHeal for the protection of its business and in the interests of Users.
                            </h5>
                        </div>
                        <h5>4.4 GetMeHeal ADS DISPLAY RIGHTS</h5>
                        <h5 className='ms-4'>GetMeHeal reserves the rights to display sponsored ads on the Website. These ads would be marked as “Sponsored ads”. Without prejudice to the status of other content, GetMeHeal will not be liable for the accuracy of information or the claims made in the Sponsored ads. GetMeHeal does not encourage the Users to visit the Sponsored ads page or to avail any services from them. GetMeHeal will not be liable for the services of the providers of the Sponsored ads.
                            You represent and warrant that you will use these Services in accordance with applicable law. Any contravention of applicable law as a result of your use of these Services is your sole responsibility, and GetMeHeal accepts no liability for the same."

                        </h5>
                        <h5>
                            4.5 PRACTITIONER UNDERTAKING
                        </h5>
                        <h5 className='ms-4'>
                            The Practitioner is and shall be duly registered, licensed and qualified to practice medicine/ provide health care, wellness services, as per applicable laws/regulations/guidelines set out by competent authorities and the Practitioner shall not be part of any arrangement which will prohibit him/her from practicing medicine within the territory of India. The Practitioner shall at all times ensure that all the applicable laws that govern the Practitioner shall be followed and utmost care shall be taken in terms of the consultation/ services being rendered.
                        </h5>
                    </div>
                    <div className="section-title mt-4"><h2>5.TERMINATION</h2></div>
                    <div className='row'>
                        <h5>
                            5.1 GetMeHeal reserves the right to suspend or terminate a User’s access to the Website and the Services with or without notice and to exercise any other remedy available under law, in cases where,
                        </h5>
                        <div className='ms-4'>
                            <h5>
                                a. Such User breaches any terms and conditions of the Agreement;
                            </h5>
                            <h5>
                                b. A third party reports violation of any of its right as a result of your use of the Services;
                            </h5>
                            <h5>
                                c. GetMeHeal is unable to verify or authenticate any information provide to GetMeHeal by a User;
                            </h5>
                            <h5>
                                d. GetMeHeal has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of such User; or
                            </h5>
                            <h5>
                                e. GetMeHeal believes in its sole discretion that User’s actions may cause legal liability for such User, other Users or for GetMeHeal or are contrary to the interests of the Website.
                            </h5>
                        </div>
                        <h5>
                            5.2 Once temporarily suspended, indefinitely suspended or terminated, the User may not continue to use the Website under the same account, a different account or re-register under a new account. On termination of an account due to the reasons mentioned herein, such User shall no longer have access to data, messages, files and other material kept on the Website by such User. The User shall ensure that he/she/it has continuous backup of any medical services the User has rendered in order to comply with the User’s record keeping process and practices.
                        </h5>
                    </div>

                    <div className="section-title mt-4"><h2>6. LIMITATION OF LIABILITY</h2></div>
                    <div className='row'>
                        <h5>
                            In no event, including but not limited to negligence, shall GetMeHeal, or any of its directors, officers, employees, agents or content or service providers (collectively, the “Protected Entities”) be liable for any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising from, or directly or indirectly related to, the use of, or the inability to use, the Website or the content, materials and functions related thereto, the Services, User’s provision of information via the Website, lost business or lost End-Users, even if such Protected Entity has been advised of the possibility of such damages. In no event shall the Protected Entities be liable for:
                        </h5>
                        <div className='ms-4'>
                            <h5>
                                a. provision of or failure to provide all or any service by Practitioners to End- Users contacted or managed through the Website;
                            </h5>
                            <h5>
                                b. any content posted, transmitted, exchanged or received by or on behalf of any User or other person on or through the Website;
                            </h5>
                            <h5>
                                c. any unauthorized access to or alteration of your transmissions or data; or
                            </h5>
                            <h5>
                                d. any other matter relating to the Website or the Service.
                            </h5>
                        </div>
                        <h5>
                            In no event shall the total aggregate liability of the Protected Entities to a User for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to, negligence or otherwise) arising from this Agreement or a User’s use of the Website or the Services exceed, in the aggregate Rs. 500/- (Rupees Five Hundreds Only).
                        </h5>
                    </div>
                    <div className="section-title mt-4"><h2>7.RETENTION AND REMOVAL</h2></div>
                    <div className='row'>
                        <h5>
                            GetMeHeal may retain such information collected from Users from its Website or Services for as long as necessary, depending on the type of information; purpose, means and modes of usage of such information; and according to the SPI Rules. Computer web server logs may be preserved as long as administratively necessary.
                        </h5>
                    </div>

                    <div className="section-title mt-4"><h2>8. APPLICABLE LAW AND DISPUTE SETTLEMENT</h2></div>
                    <div className='row'>
                        <h5>
                            8.1 You agree that this Agreement and any contractual obligation between GetMeHeal and User will be governed by the laws of India.
                        </h5>
                        <h5>
                        8.2 Any dispute, claim or controversy arising out of or relating to this Agreement, including the determination of the scope or applicability of this Agreement to arbitrate, or your use of the Website or the Services or information to which it gives access, shall be determined by arbitration in India, before a sole arbitrator appointed by GetMeHeal. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be Bangalore. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.
                        </h5>
                        <h5>
                        8.3 Subject to the above Clause 8.2, the courts at Bengaluru shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement, your use of the Website or the Services or the information to which it gives access.
                        </h5>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default TermsCondition
