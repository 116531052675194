import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-validation/build/form';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import $ from 'jquery';
import { getdr, autocomplete } from '../actions/drsearch';
import { useNavigate } from 'react-router-dom';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const SeacrhHeader = () => {
  const MySwal = withReactContent(Swal);
  const form = useRef();
  const [location, setLocation] = useState('');
  const [city, setCity] = useState('');
  const [specilization, setSpecialization] = useState('');
  const [drhs, setDrhs] = useState('');
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);

  const { message } = useSelector(state => state.message);
  const [successful, setSuccessful] = useState(false);
  const alert = useAlert();
  let history = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);
  // useEffect(() => {
  //   var filters =localStorage.getItem('filter')
  //   console.log(filters);
  //   if(!!filters){
  //     setCity( JSON.parse(filters)["practice.city"] );
  //   }
  // },[])
  window.$("#hSearch").attr("href", "#");

  const onChangelocation = e => {
    const location = e.target.value;
    setLocation(location);
  };

  const onChangecity = e => {
    const city = e.target.value;
    setCity(city);
  };

  window.$("#city").autocomplete({
    source: function (request, response) {
      const city = request.term;
      if (city.length > 2) {
        autocomplete('city', city).then((res) => {
          response(res.data);
        })
      }
    },
    select: function (e, ui) {
      if (!!ui.item) {
        setCity(ui.item.value);
      }
    },
    change: function (e, ui) {
      if (!!ui.item) {
        setCity(ui.item.value);
      }
    }
  });

  const onChangeSpecilization = e => {
    const specialization = e.target.value;
    setSpecialization(specialization);
  };

  window.$("#specilization").autocomplete({
    delay: 100,
    source: function (request, response) {
      const specialization = request.term;
      if (specialization.length > 2) {
        autocomplete('services', specialization).then((res) => {
          response(res.data);
        })
      };
    },
    select: function (e, ui) {
      console.log("aaa" + ui.item)
      if (!!ui.item) {
        setSpecialization(ui.item.value);
      }
    },
    change: function (e, ui) {
      console.log("bbb" + ui.item)
      if (!!ui.item) {
        setSpecialization(ui.item.value);
      }
    }
    // select: selectItem,
  });
  const onChangeDrhs = e => {
    const drhs = e.target.value;
    setDrhs(drhs);
  }
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    var filter = {};
    // "services": "",

    if (city.length > 0) {
      filter["practice.city"] = city
    }
    if (specilization.length > 0) {
      filter["services"] = specilization
    }
    // localStorage.setItem('filter', JSON.stringify(filter));
    var drSearch = {
      "filter": filter,
      "containingText": {
        "doctor_name": drhs

      },
      "sort": {
        "isAssociated": "-1"
      },
      "pagination": { "pageSize": 10, "offset": 0 }
    }
    dispatch(getdr(drSearch))
      .then((res) => {
        console.log("Dr lIst", res)
        if (res.length > 0) {
          history("/dr");
        }
        else {
          MySwal.fire({
            title: <strong>Please Select Valid options</strong>,
            icon: 'info',
            timer: 2000
          })
          setLoading(false);
          return false;
        }
        setLoading(false);
      })
      .catch((ex) => {
        console.log(ex)
        setLoading(false);
      });
  };
  // if (successful) {
  //   return <Redirect to="/dr" />;
  // }
  return (
    <div id='searchHeaderMain'>
      <div className={"overlay " + (loading ? '' : 'd-none')}>
        <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
      </div>
      <div id="topbar" class="d-flex align-items-center fixed-top">
        <div class="container d-flex justify-content-center">
          <Form onSubmit={handleLogin} ref={form}>
            <div class="contact-info row align-items-center">
              {/* <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text border-end-0 bg-white px-1" id="basic-addon1"><i class="bi bi-geo-alt"></i></span>
                </div>
                <input type="text" class="form-control border-start-0" placeholder="Location" aria-label="Location" aria-describedby="basic-addon1"
                  value={location}
                  onChange={onChangelocation} />
              </div> */}
              <div class="col-md-3 my-2">
                <input type="text" class="form-control" placeholder="City" aria-label="City" id="city"
                  value={city}
                  onChange={onChangecity} aria-describedby="basic-addon1" autofocus />
              </div>
              <div class="col-md-3 my-2">
                <input type="text" class="form-control" placeholder="Specilization" id="specilization"
                  value={specilization}
                  onChange={onChangeSpecilization} aria-label="Specilization" aria-describedby="basic-addon1" />
              </div>
              <div class="col-md-3 my-2">
                <input type="text" class="form-control" placeholder="Doctor/Clinic/Hospital"
                  value={drhs}
                  onChange={onChangeDrhs} aria-label="Doctor/Clinic/Hospital" aria-describedby="basic-addon1" />
              </div>
              <div class="col-md-3 text-right my-2">
                <button type="submit" class="btn btn-primary  btn-sm ms-3 rounded-pill d-flex seachHeadermain" id="seachHeader" >Search</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
export default SeacrhHeader