import {
  PROFILE,
  DOCTORS_FAIL,
  SET_MESSAGE,
} from './types';

import UserService from '../services/user.service';
import PatientService from '../services/patient.service';

export const getProfile = () => dispatch => UserService.getProfile().then(
  response => {
    debugger
    console.log("Profile", response)
    if(!!response.data.data.role){
      
    }
    else{
      response.data.data.role='user'
    }

    localStorage.setItem('profile', JSON.stringify(response.data.data));
    dispatch({
      type: PROFILE,
      payload: { profile: response.data },
    });

    dispatch({
      type: SET_MESSAGE,
      payload: "",
    });

    return Promise.resolve();
  })
export const savePrescription = (dr, appointment_id, prescription) => UserService.savePrescription(dr, appointment_id, prescription).then(
  response => {
    return response.data;
  })
export const getPrescription = (dr, appointment_id, prescription_id) => UserService.getPrescription(dr, appointment_id, prescription_id).then(
  response => {
    return response.data;
  })
export const getPatientforDoctor = (appointment_id, userID) => PatientService.getPatientforDoctor(appointment_id, userID).then(
  response => {
    return response.data;
  })