import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://easycuresearch.onrender.com/';
const API_URLDR = 'https://easycure.onrender.com/';

const postSearchDrs = (drSearch) => axios.post(`${API_URL}search`, drSearch).then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const drdetails = (drSearch) => axios.get(`${API_URLDR}drdetails?uniquename=${drSearch}`).then(response => {
  if (response.data == null) {
    response.data = []
  }

  return response;
});
const autocomplete = (field, value) => axios.get(`${API_URLDR}autocomplete/${field}/${value}`).then(response => {
  if (response.data == null) {
    response.data = []
  }

  return response;
});
const saveRating = (dr, rating) => axios.post(`${API_URLDR}restrictedArea/${dr}/rating/`, rating, { headers: authHeader() }).then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const getComments = (dr) => axios.get(`${API_URLDR}restrictedArea/${dr}/comment/`, { headers: authHeader() }).then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const saveComments = (dr, comment) => axios.post(`${API_URLDR}restrictedArea/${dr}/comment/`, comment, { headers: authHeader() }).then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const getStats = (dr) => axios.get(`${API_URLDR}stats/`, '').then(response => {
  if (response.data == null) {
    response.data = {}
  }
  return response;
});
const saveContact = (contactDt) => axios.post(`${API_URLDR}contact/`, contactDt, '').then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const addDoctor = (doctor) => axios.post(`${API_URLDR}adddr/`, doctor, '').then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const addHospital = (hospital) => axios.post(`${API_URLDR}addhospital/`, hospital, '').then(response => {
  if (response.data == null) {
    response.data = []
  }
  return response;
});
const addIntimate = (id) => axios.post(`${API_URLDR}intimateDr/${id}`, '', '').then(response => {
  if (response.data == null) {
    response.data = {}
  }
  return response;
});
const verificationData = (formdata) => axios.post(`${API_URLDR}restrictedArea/verificationData`, formdata, { headers: authHeader() }).then(response => {
  if (response.data == null) {
    response.data = {}
  }
  return response;
});
const updateDrProfile = (doctor) => axios.post(`${API_URLDR}restrictedArea/updateDrProfile`, doctor,{ headers: authHeader() }).then(response => {
  if (response.data == null) {
    response.data = {}
  }
  return response;
});
export default {
  postSearchDrs, drdetails, autocomplete, saveRating, getComments,updateDrProfile,
  saveComments, getStats, saveContact, addDoctor, addHospital,addIntimate,verificationData
}
