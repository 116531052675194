import React, { useEffect, useState } from 'react';
import { saveContact } from '../actions/drsearch';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
export default (params) => {
  const MySwal = withReactContent(Swal);

  var userprofile = JSON.parse(localStorage.getItem("profile"));
  if (!!userprofile) {
    //setData(userprofile);
  }
  else {
    userprofile = { "name": "", "email": "", "mobile": "", "subject": "", "message": "" }

  }
  const [data, setData] = useState(userprofile);
  const handleChange = (evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = data;
    console.log(rowsInput);
    rowsInput[name] = value;
    setData(rowsInput);
  }
  const saveContacts = () => {
    console.log(data);
    delete data["updated_date"];
    delete data["_id"]
    delete data["user_password"]
    saveContact(data).then((es) => {
      MySwal.fire({
        title: <strong>We will Contact Soon!</strong>,
        icon: 'success'
      }).then(function () {
        window.$(':input', '#contactForm')
          .not(':button, :submit, :reset, :hidden')
          .val('')
          .prop('checked', false)
          .prop('selected', false);
      });
    }).catch((errror) => {
      MySwal.fire({
        title: <strong>Somthing went wrong!</strong>,
        icon: 'error'
      })
    });
  }

  return (<section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2>Contact Us</h2>
        {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
      </div>
    </div>

    {/* <div>
      <iframe style={{ border: "0", width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
    </div> */}

    <div class="container">
      <div class="row my-5">

        <div class="col-lg-4">
          <div class="info">
            {/* <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>A108 Adam Street, New York, NY 535022</p>
            </div> */}

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p><a href="mailto:info@getmeheal.com"> info@getmeheal.com</a></p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p><a href="tel:+91 9024804576">+91 9024804576</a></p>
            </div>

          </div>

        </div>

        <div class="col-lg-8 mt-5 mt-lg-0" id='contactForm'>


          <div class="row" >
            <div class="col-md-4 form-group">
              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required defaultValue={userprofile.name}
                onChange={(evnt) => (handleChange(evnt))} />
            </div>
            <div class="col-md-4 form-group mt-3 mt-md-0">
              <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required defaultValue={userprofile.email}
                onChange={(evnt) => (handleChange(evnt))} />
            </div>
            <div class="col-md-4 form-group mt-3 mt-md-0">
              <input type="text" class="form-control phonenumber" name="mobile" id="mobile" placeholder="Your Mobile No" min={0} minLength={10} maxLength={10} defaultValue={userprofile ? '' : userprofile.mobile.split('+91')[1]}
                onChange={(evnt) => (handleChange(evnt))} />
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required
              onChange={(evnt) => (handleChange(evnt))} />
          </div>
          <div class="form-group mt-3">
            <textarea class="form-control" name="message" rows="5" placeholder="Message" required
              onChange={(evnt) => (handleChange(evnt))}></textarea>
          </div>
          {/* <div class="my-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div> */}
          <div class="text-center mt-2"><button type="submit" className='btn btn-primary' onClick={saveContacts}>Send Message</button></div>


        </div>

      </div>

    </div>
  </section>
  )
}