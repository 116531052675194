import { Outlet } from 'react-router-dom';

// project imports
import Script from '../../component/scripts';
import Headers from '../../component/header';
import Footer from '../../component/footer';
import Login from '../../component/Login';
// import { Container } from '@mui/material'
// ==============================|| MINIMAL LAYOUT ||============================== //

const MainLayout = () => (
    <>
        <Script />
        <Headers Login={true} IsLogin={true}></Headers>
        <Login></Login>
        <div className='pt-5'>
            <Outlet />
        </div>
        <Footer IsContact={true}></Footer>
        {/* <Customization /> */}
    </>
);

export default MainLayout;
