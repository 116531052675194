import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from './Loadable';

// dashboard routing
const SeacrhHeader = Loadable(lazy(() => import('../component/searchheader')));
const Whyus = Loadable(lazy(() => import('../component/whyus')));
const Aboutus = Loadable(lazy(() => import('../component/aboutus')));
const Appointment = Loadable(lazy(() => import('../component/appointment')));
const Contact = Loadable(lazy(() => import('../component/contact')));
const Drlist = Loadable(lazy(() => import('../component/drlist')));
const Drdetails = Loadable(lazy(() => import('../component/drdetails')));
const Callback = Loadable(lazy(() => import('../component/paytmcallback')));
const Myappointment = Loadable(lazy(() => import('../component/dashboard/myappointment')));
const Drappointment = Loadable(lazy(() => import('../component/doctor/drappointments')));
const Patientprofile = Loadable(lazy(() => import('../component/dashboard/patientprofile')));
const Drprofile = Loadable(lazy(() => import('../component/doctor/drprofile')));
const Prescriptionbuilder = Loadable(lazy(() => import('../component/doctor/prescriptionbuilder')));
const Viewprescription = Loadable(lazy(() => import('../component/dashboard/viewprescription')));
const MainAboutUs = Loadable(lazy(() => import('../component/MainAboutUs')));
const Adddoctorhospital = Loadable(lazy(() => import('../component/doctor/adddoctorhospital')));
const TermsCondition = Loadable(lazy(() => import('../component/termconditions')));
const RefundPolicy = Loadable(lazy(() => import('../component/refundpolicy')));
const Scanbook = Loadable(lazy(() => import('../component/scanbook')));
// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <> <SeacrhHeader></SeacrhHeader>
                <Whyus></Whyus>
                <Aboutus></Aboutus>
                <Appointment></Appointment>
                <Contact></Contact></>
        },
        {
            path: '/dr',
            element: <> <SeacrhHeader></SeacrhHeader>
                <Drlist></Drlist></>
        },
        {
            path: '/drdetails/:id',
            element: <> <SeacrhHeader />
                <Drdetails /></>
        },
        {
            path: '/callback/:id',
            element: <Callback />
        },
        {
            path: '/appointments',
            element: <Myappointment />
        },
        {
            path: '/drappointments',
            element: <Drappointment />
        },
        {
            path: '/profile',
            element: <Patientprofile />
        },
        {
            path: '/drprofile',
            element: <Drprofile />
        },
        {
            path: '/prescriptionbuilder/:uname',
            element: <Prescriptionbuilder />
        },
        {
            path: '/viewprescription/:uname',
            element: <Viewprescription />
        },
        {
            path: '/aboutus',
            element: <MainAboutUs />
        },
        {
            path: '/adddoctor',
            element: <Adddoctorhospital />
        },
        {
            path: '/terms',
            element: <TermsCondition />
        },
        {
            path: '/refundpolicy',
            element: <RefundPolicy />
        },
        {
            path: '/qrbooking/:id',
            element: <Scanbook />
        },
    ]
};

export default MainRoutes;
