import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { data } from 'jquery';
import Patient from '../../services/patient.service';
import DataTable from "react-data-table-component";
import * as moment from 'moment';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top:45%;
`;
const DrAppointment = (props) => {
    const [myAppList, setContent] = useState('');
    const [url, setUrl] = useState('');
    const { user: currentUser } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const userName = JSON.parse(localStorage.getItem('user'));
    const profile = JSON.parse(localStorage.getItem('profile'));
    const joincall = (callId,drname) => {
        window.$('#exampleModalLabel')[0].innerText = 'Video Confrence With ' + drname.split('-').join(' ');
        //const user = JSON.parse(localStorage.getItem('user'));
        console.log("https://easycure.onrender.com/call?id=" + callId + "&token=" + userName.access_token)
        setUrl("https://easycure.onrender.com/call?id=" + callId + "&token=" + userName.access_token)
        window.$('#vdocall').modal('show');
    }
    const columns = [
        {
            id: 1,
            name: "Patient Name",
            selector: (row) => row.userId,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Date & Time",
            selector: (row) => moment(row.slotStartsAt).format('DD-MM-YYYY, h:mm a'),
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Status",
            selector: (row) => (<div>{row.status == "BOOKED" ? <button type="button" class="btn btn-success btn-sm">{row.status}</button> : <button type="button" class="btn btn-danger btn-sm">PAYMENT DUE</button>}</div>),
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Update",
            cell: (row) => (<div className="d-inline-flex"><button class="btn btn-sm btn-outline-primary w-100 me-2" onClick={() => joincall(row.callId,row.userId)} title="Video Consult"><i class="bi bi-camera-video-fill  me-2"></i></button>
                {row.prescription_id ? <a class="btn btn-sm btn-outline-primary w-100 me-2" href={"/prescriptionbuilder/" + row.userId + "-" + row._id + "-" + row.prescription_id}><i class="bi bi-download  me-2" title="ReUpload Prescription"></i></a> : <a class="btn btn-sm btn-outline-primary w-100 me-2" href={"/prescriptionbuilder/" + row.userId + "-" + row._id}><i class="bi bi-upload  me-2" title="Upload Prescription"></i></a>}
                {row.prescription_id ? <a class="btn btn-sm btn-outline-primary w-100" href={"/prescriptionbuilder/" + row.userId + "-" + row._id + "-" + row.prescription_id}><i class="bi bi-download  me-2" title="Complete Prescription"></i></a> : ""}
            </div>

            ),
            sortable: false,
            center: true,
            reorder: true
        }
    ];
    useEffect(() => {
        console.log("user", profile);
        setLoading(true);
        Patient.getdrAppointment(profile.username, 'BOOKED')
            .then((res) => {
                console.log(res);
                setContent(res.data.data);
                //setSuccessful(true);
                setLoading(false);
            });
    }, []);

    if (!currentUser) {
        return <Navigate to="/" />;
    }
    return (
        <div>

            <div className='row mt-5 mr-1' style={{ 'marginRight': 'unset' }}>

                <div className='col-md-12' style={{ 'padding': '0 15%' }}>
                    <div class="card shadow-lg">
                        <div class="card-body"> <DataTable
                            columns={columns}
                            data={myAppList}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                        />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="vdocall" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-lg">
                    <div class="modal-content">
                         <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel" style={{ 'text-transform': 'capitalize' }}></h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => window.$('#vFrame').attr('src', window.$('#vFrame').attr('src'))}></button>
                        </div> 
                        <div class="modal-body">
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ "right": "0", "position": "absolute", "top": "-3px", "font-size": "small" }} onClick={() => window.$('#vFrame').attr('src', window.$('#vFrame').attr('src'))}></button> */}
                            <iframe src={url}
                                allow="camera; microphone" height={"305"} width={"765"} id="vFrame" allowfullscreen>
                            </iframe>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={"overlay " + (loading ? '' : 'd-none')}>
                <HashLoader color={"36D7B7"} loading={loading} size={150} css={override} />
            </div>
        </div >
    )
}
export default DrAppointment